import { Card, Col, PageHeader, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import PromotionsForm from "../components/Promotions/PromotionsForm";
import history from "../helpers/history";
import useAllPromotions from "../hooks/useAllPromotions";

const PromotionsEdit = () => {
    const [itemToEdit, setItemToEdit] = useState({});
    const [promotionsLoading, promotionsData, promotionsError] =
        useAllPromotions();

    const { id } = useParams();

    useEffect(() => {
        if (!id) return history.push("/promotions");

        const initialValues = Array.from(promotionsData).find(
            (promo) => promo.id === id
        );

        setItemToEdit({ ...initialValues });
    }, [id, promotionsData]);

    return (
        <React.Fragment>
            <Row justify="center">
                <Col span="22" sm="24">
                    <PageHeader title="Editar promocion" />
                </Col>
            </Row>
            <Row justify="center">
                <Col span="22" sm="24">
                    <Card>
                        {Object.keys(itemToEdit).length > 0 && (
                            <PromotionsForm
                                formToEdit={true}
                                initialValues={itemToEdit}
                            />
                        )}
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PromotionsEdit;
