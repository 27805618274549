import React, { Fragment } from "react";
import { Row, Col, PageHeader, Card } from "antd";
import {PriceMatchUpdate, PriceMatchUpdateAccMail, PriceMatchUpdateAdminMail} from "../components/Refunds/RefundUpdateOptions";
import {    PriceMatchOptionsMail, 
            PriceMatchOptionsProduct, 
            PriceMatchOptionsAccountantMail,
            PriceMatchOptionsAdminMail 
        } from "../components/Refunds/Options"

const CustomCard = ({ children, ...props }) => {
  return <Card {...props}>{children}</Card>;
};
const RefundsOptionsPage = () => {
  const mailAlertsOptionsId = "opt_0231c895-31c2-4909-8c90-6941351fbed8"
  const ProductBanOptionsId = "opt_dcfa419e-d6ef-48f9-85df-3653d78e130e"
  const AccountantEmailOptionsId = "opt_32eb6227-76b8-45d3-9004-51a7be8ce8d6"
  const AdminEmailOptionsId = "opt_c025b58a-e890-4d6f-8322-d102d94695bb"
  
  return (
    <Fragment>
      <Row justify="center">
        <Col span="22" sm="24">
          <PageHeader title="Opciones para Solicitudes de Reembolso " />
        </Col>
        <Col span="12" sm="24">
          <CustomCard title="Configuración de Alertas de Email">
            <PriceMatchUpdate opt_id={mailAlertsOptionsId} />
          </CustomCard>
        </Col>
        <Col span="12" sm="24">
          <CustomCard title="Configuración Actual">
              <PriceMatchOptionsMail opt_id={mailAlertsOptionsId} />
          </CustomCard>
        </Col>
      </Row>
      <Row justify="center">
        <Col span="12" sm="24">
          <CustomCard title="Configuración de Desactivación de Productos">
            <PriceMatchUpdate opt_id={ProductBanOptionsId} />
          </CustomCard>
        </Col>
        <Col span="12" sm="24">
          <CustomCard title="Configuración Actual">
          <PriceMatchOptionsProduct opt_id={ProductBanOptionsId} />
          </CustomCard>
        </Col>
      </Row>
      <Row justify="center">
        <Col span="12" sm="24">
          <CustomCard title="Configuración de Correo Contabilidad">
            <PriceMatchUpdateAccMail opt_id={AccountantEmailOptionsId} />
          </CustomCard>
        </Col>
        <Col span="12" sm="24">
          <CustomCard title="Configuración Actual">
          <PriceMatchOptionsAccountantMail opt_id={AccountantEmailOptionsId} />
          </CustomCard>
        </Col>
      </Row>
      <Row justify="center">
        <Col span="12" sm="24">
          <CustomCard title="Configuración de Correo Admin">
            <PriceMatchUpdateAdminMail opt_id={AdminEmailOptionsId} />
          </CustomCard>
        </Col>
        <Col span="12" sm="24">
          <CustomCard title="Configuración Actual">
          <PriceMatchOptionsAdminMail opt_id={AdminEmailOptionsId} />
          </CustomCard>
        </Col>
      </Row>
    </Fragment>
  );
};

export default RefundsOptionsPage;
