import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import getOptionsProducts from "../store/refunds/getOptionsProduct/actions";

const useOptionProduct = (opt_id) => {
    const dispatch = useDispatch();
    const { loading, data, error } = useSelector((store) => store.getOptionsProducts);
    useEffect(() => {
            dispatch(getOptionsProducts(opt_id));
        
    }, [dispatch]);

    return [loading, data, error];
};
export default useOptionProduct;
