const stateMessages = (state) => {
    switch (state) {
        case "pending":
            return {
                color: "purple",
                timelineText: "Solicitud creada",
                tagText: "Pendiente",
            };
        case "revision":
            return {
                color: "blue",
                timelineText: "Solicitud en revision",
                tagText: "En Revision",
            };
        case "accepted":
            return {
                color: "orange",
                timelineText: "Solicitud aceptada",
                tagText: "Aceptado",
            };
        case "success":
            return {
                color: "green",
                timelineText: "Solicitud completada",
                tagText: "Completado",
            };
        case "deny":
            return {
                color: "red",
                timelineText: "Solicitud rechazada",
                tagText: "Rechazado",
            };
        case "processing":
            return {
                color: "purple",
                timelineText: "Datos bancarios recibidos",
                tagText: "Devolución Pendiente",
            };
        default:
            return {
                color: "",
                timelineText: "",
                tagText: "",
            };
    }
};

export default stateMessages;
