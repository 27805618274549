import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import shortid from "shortid";
import getVolumeDiscounts from "../store/volume_discounts/getAll/actions";

const useAllVolumeDiscounts = () => {
    const [formatedData, setFormatedData] = useState([]);

    const dispatch = useDispatch();

    const { loading, data, error } = useSelector(
        (store) => store.getVolumeDiscounts
    );
    console.log(loading, data, error);

    useEffect(() => {
        if (!data[0]) {
            dispatch(getVolumeDiscounts());
        }
    }, [dispatch]);

    useEffect(() => {
        setFormatedData(
            data.reduce((acc, ele) => {
                acc.push({
                    key: shortid.generate(),
                    ...ele,
                });
                return acc;
            }, [])
        );
    }, [data]);

    return [loading, formatedData, error];
};
export default useAllVolumeDiscounts;
