import React from "react";
import { Modal, Form, Input } from "antd";
import { useDispatch } from "react-redux";
import addVendor from "../../store/crossdocking/add/actions";

const AddVendorModal = ({ openModal, setOpenModal }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            dispatch(addVendor(values));
            setTimeout(() => {
                handleCloseModal();
            }, 1500);
        });
    };

    return (
        <Modal
            visible={openModal}
            title="Crear nuevo vendor"
            okText="Crear"
            cancelText="Cancelar"
            onCancel={handleCloseModal}
            onOk={handleSubmit}
        >
            <Form form={form} layout="vertical" name="vendorAdd">
                <Form.Item
                    name="vendor_name"
                    label="Nombre de la marca"
                    help="El nombre de la marca debe ser preciso, considera minisculas, mayusculas y espacios"
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Por favor, completa este campo",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="email"
                    label="Email de contacto"
                    help="Cuando un producto de la marca sea ordenado, se enviara un email a esta direccion."
                    rules={[
                        {
                            type: "email",
                            required: true,
                            message: "Por favor, ingresa un email valido",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="contact_name"
                    label="Nombre del contacto"
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Por favor, completa este campo",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddVendorModal;
