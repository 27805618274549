import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import getRefunds from "../store/refunds/getAll/actions";

const useAllRefunds = () => {
    const dispatch = useDispatch();
    const { loading, data, error } = useSelector((store) => store.getRefunds);
    useEffect(() => {
        if (!data[0]) {
            dispatch(getRefunds());
        }
    }, [dispatch]);

    return [loading, data, error];
};
export default useAllRefunds;
