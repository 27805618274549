import React from "react";
import { Row, Col, PageHeader } from "antd";
import RefundsTable from "../components/Refunds/RefundsTable";
import useAllRefunds from "../hooks/useAllRefunds";
import { getUserInToken } from "../helpers/Auth";

const RefundsPage = () => {
    const [loading, data, error] = useAllRefunds();
    const { user } = getUserInToken();

    const dataFormatted = () => {
        if (Array.from(user.roles).includes("admin")) {
            return data;
        }
        if (Array.from(user.roles).includes("contabilidad")) {
            return data.filter((d) => d.request_state === "processing");
        }

        return [];
    };

    return (
        <Row justify="center">
            <Col span="22" sm="24">
                <PageHeader title="Solicitudes de Reembolso" />
            </Col>
            <Col span="22" sm="24">
                <RefundsTable loading={loading} data={dataFormatted()} />
            </Col>
        </Row>
    );
};

export default RefundsPage;
