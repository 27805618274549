import React from "react";

import PickupsTable from "../components/Pickups/PickupsTable";
import usePickupLocations from "../hooks/usePickupLocations";
import { Row, Col, PageHeader } from "antd";

const PickupsPage = () => {
    const [loading, data, error] = usePickupLocations();
    return (
        <Row justify="center">
            <Col span="22" sm="24">
                <PageHeader title="Habilitación y deshabilitación de tiendas para retiro" />
            </Col>
            <Col span="22" sm="24">
                <PickupsTable locations={data} dataLoading={loading} />
            </Col>
        </Row>
    );
};

export default PickupsPage;
