import { ACTIONS } from "./actions";

const initialState = {
    loading: false,
    data: [],
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_ALL_VOLUME_DISCOUNTS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.GET_ALL_VOLUME_DISCOUNTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: [...action.payload],
                error: null,
            };
        case ACTIONS.GET_ALL_VOLUME_DISCOUNTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
