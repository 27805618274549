import { Card, Col, PageHeader, Row } from "antd";
import React from "react";
import PromotionsForm from "../components/Promotions/PromotionsForm";

const PromotionsNew = () => {
    return (
        <React.Fragment>
            <Row justify="center">
                <Col span="22" sm="24">
                    <PageHeader title="Nueva Promocion" />
                </Col>
            </Row>
            <Row justify="center">
                <Col span="22" sm="24">
                    <Card>
                        <PromotionsForm />
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default PromotionsNew;
