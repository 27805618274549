import React from "react";
import { Row, Col, PageHeader, Card, Tag, Skeleton, Breadcrumb, Button } from "antd";
import { useParams } from "react-router-dom";
import MoneyFormat from "../helpers/MoneyFormat";
import useRefund from "../hooks/useRefund";

import RefundImages from "../components/Refunds/Refund/RefundImages";
import RefundUpdate from "../components/Refunds/Refund/RefundUpdate";
import RefundTimeLine from "../components/Refunds/Refund/RefundTimeLine";
import RefundClientData from "../components/Refunds/Refund/RefundClientData";
import RefundOrder from "../components/Refunds/Refund/RefundOrder";

import stateMessages from "../helpers/PriceMatchStates";

const Refund = () => {
    const { refund_id } = useParams();

    const [loading, data, error] = useRefund(refund_id);

    const {
        order = {
            createdAt: "",
            products: [],
        },
        images = [],
        amount = 0,
        request_state = "",
        updates = [],
        client = {
            displayName: "",
            bankData: {},
        },
        product_id = 0,
        order_id = 0,
        retail_name = "",
        product_url,
    } = data;
 
    const CustomCard = ({ children, ...props }) => {
        return (
            <Card {...props} loading={loading}>
                {children}
            </Card>
        );
    };

    const state = stateMessages(request_state);

    return (
        <>
            <Row justify="center">
                <Col span={16}>
                    <PageHeader
                        style={{ margin: "0px 10px" }}
                        title={
                            loading
                                ? "Cargando orden"
                                : `Solicitud de reembolso: ${client.displayName || ""}`
                        }
                        subTitle={!loading && <Tag color={state.color}>{state.tagText}</Tag>}
                    />
                    {!loading && (
                        <Breadcrumb style={{ margin: "0px 10px" }}>
                            <Breadcrumb.Item>Home</Breadcrumb.Item>
                            <Breadcrumb.Item>Reembolsos</Breadcrumb.Item>
                            <Breadcrumb.Item>Reembolso {client.displayName}</Breadcrumb.Item>
                        </Breadcrumb>
                    )}
                </Col>
            </Row>
            <Row justify="center">
                <Col span={10}>
                    <CustomCard
                        title="Orden"
                        extra={
                            <a
                                href={`https://allnutrition-mv-temp1.myshopify.com/admin/orders/${order_id}?orderListBeta=true`}
                                target="_blank"
                                style={{ color: "black" }}
                            >
                                <strong>ID:</strong> {order_id}
                            </a>
                        }
                    >
                        <RefundOrder order={order} product_id={product_id} />
                    </CustomCard>
                    {images && (
                        <CustomCard title="Imágenes">
                            <RefundImages images={images} />
                        </CustomCard>
                    )}
                </Col>
                <Col span={6}>
                    <CustomCard size="small" title="Monto">
                        <p>
                            Mas barato en: {"  "}
                            <b>
                                <a href={product_url} target="_blank">
                                    {retail_name}
                                </a>
                            </b>
                        </p>
                        <p style={{ fontSize: "1.2rem" }}>
                            <strong>Precio del producto:</strong> {MoneyFormat(amount)}
                        </p>
                    </CustomCard>
                    <CustomCard size="small" title="Actualizar Estado">
                        <RefundUpdate state={request_state} refund_id={refund_id} />
                    </CustomCard>
                    <CustomCard size="small" title="Informacion del cliente">
                        <RefundClientData client={client} />
                    </CustomCard>
                    <CustomCard size="small" title="Timeline">
                        <RefundTimeLine updates={updates} />
                    </CustomCard>
                </Col>
            </Row>
        </>
    );
};

export default Refund;
