import React, { useState } from "react";
import { Carousel } from "antd";
import styled from "styled-components";
import Modal from "antd/lib/modal/Modal";

const ImageStyled = styled.img`
    width: 100%;
    height: 100%;
    max-height: 500px;
    margin: 0 auto;
    object-fit: cover;
`;

const ModalImageStyled = styled.img`
    width: 100%;
    margin: 0 auto;
    object-fit: cover;
`;

const RefundImages = ({ images = "" }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalImage("");
    };

    const handleOpenModal = (img) => {
        setModalImage(img);
        setModalOpen(true);
    };

    return (
        <>
            <Modal
                title="Imagen"
                visible={modalOpen}
                onOk={handleCloseModal}
                onCancel={handleCloseModal}
                okText="OK"
                cancelText="Cerrar"
            >
                <ModalImageStyled src={modalImage} />
            </Modal>
            <Carousel autoplay>
                {Array.from(images).map((image) => (
                    <ImageStyled
                        src={image}
                        key={image}
                        loading="lazy"
                        onClick={() => {
                            handleOpenModal(image);
                        }}
                    />
                ))}
            </Carousel>
        </>
    );
};

export default RefundImages;
