// prettier-ignore
import { Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import { ControlledEditor as MonacoEditor } from "@monaco-editor/react";
import useClipboard from "react-use-clipboard";
import Notification from "../Utils/Notification";

const PromotionsRubyCode = ({ promotions, dataLoading }) => {
    const [code, setCode] = useState("");
    const [isCopied, setCopied] = useClipboard(code, {
        successDuration: 2000,
    });

    const formatArray = (arr) => {
        return arr
            .map((discount) => {
                return JSON.stringify({});
            })
            .join(",\n        ");
    };

    useEffect(() => {
        if (!promotions[0]) return setCode("");
        setCode(
            `
class Campaign
  def initialize(condition, *qualifiers)
    @condition = (condition.to_s + '?').to_sym
    @qualifiers = PostCartAmountQualifier ? [] : [] rescue qualifiers.compact
    @line_item_selector = qualifiers.last unless @line_item_selector
    qualifiers.compact.each do |qualifier|
      is_multi_select = qualifier.instance_variable_get(:@conditions).is_a?(Array)
      if is_multi_select
        qualifier.instance_variable_get(:@conditions).each do |nested_q|
          @post_amount_qualifier = nested_q if nested_q.is_a?(PostCartAmountQualifier)
          @qualifiers << qualifier
        end
      else
        @post_amount_qualifier = qualifier if qualifier.is_a?(PostCartAmountQualifier)
        @qualifiers << qualifier
      end
    end if @qualifiers.empty?
  end

  def qualifies?(cart)
    return true if @qualifiers.empty?
    @unmodified_line_items = cart.line_items.map do |item|
      new_item = item.dup
      new_item.instance_variables.each do |var|
        val = item.instance_variable_get(var)
        new_item.instance_variable_set(var, val.dup) if val.respond_to?(:dup)
      end
      new_item
    end if @post_amount_qualifier
    @qualifiers.send(@condition) do |qualifier|
      is_selector = false
      if qualifier.is_a?(Selector) || qualifier.instance_variable_get(:@conditions).any? { |q| q.is_a?(Selector) }
        is_selector = true
      end rescue nil
      if is_selector
        raise "Missing line item match type" if @li_match_type.nil?
        cart.line_items.send(@li_match_type) { |item| qualifier.match?(item) }
      else
        qualifier.match?(cart, @line_item_selector)
      end
    end
  end

  def run_with_hooks(cart)
    before_run(cart) if respond_to?(:before_run)
    run(cart)
    after_run(cart)
  end

  def after_run(cart)
    @discount.apply_final_discount if @discount && @discount.respond_to?(:apply_final_discount)
    revert_changes(cart) unless @post_amount_qualifier.nil? || @post_amount_qualifier.match?(cart)
  end

  def revert_changes(cart)
    cart.instance_variable_set(:@line_items, @unmodified_line_items)
  end
end

class BuyXGetX < Campaign
  def initialize(condition, customer_qualifier, cart_qualifier, buy_item_selector, get_item_selector, discount, buy_x, get_x, max_sets)
    super(condition, customer_qualifier, cart_qualifier)
    @line_item_selector = buy_item_selector
    @get_item_selector = get_item_selector
    @discount = discount
    @buy_x = buy_x
    @get_x = get_x
    @max_sets = max_sets == 0 ? nil : max_sets
  end

  def run(cart)
    raise "Campaign requires a discount" unless @discount
    return unless qualifies?(cart)
    return unless cart.line_items.reduce(0) {|total, item| total += item.quantity } >= @buy_x
    applicable_buy_items = nil
    eligible_get_items = nil
    discountable_sets = 0

    # Find the items that qualify for buy_x
    if @line_item_selector.nil?
      applicable_buy_items = cart.line_items
    else
      applicable_buy_items = cart.line_items.select { |item| @line_item_selector.match?(item) }
    end

    # Find the items that qualify for get_x
    if @get_item_selector.nil?
      eligible_get_items = cart.line_items
    else
      eligible_get_items = cart.line_items.select {|item| @get_item_selector.match?(item) }
    end

    # Check if cart qualifies for discounts and limit the discount sets
    purchased_quantity = applicable_buy_items.reduce(0) { |total, item| total += item.quantity }
    discountable_sets = (@max_sets ? [purchased_quantity / @buy_x, @max_sets].min : purchased_quantity / @buy_x).to_i
    return if discountable_sets < 1
    discountable_quantity = (discountable_sets * @get_x).to_i
    # Apply the discounts (sort to discount lower priced items first)
    eligible_get_items = eligible_get_items.sort_by { |item| item.variant.price }
    eligible_get_items.each do |item|
      break if discountable_quantity == 0
      if item.quantity <= discountable_quantity
        @discount.apply(item)
        discountable_quantity -= item.quantity
      else
        new_item = item.split({ take: discountable_quantity })
        @discount.apply(new_item)
        cart.line_items << new_item
        discountable_quantity = 0
      end
    end
  end
end

class Selector
  def partial_match(match_type, item_info, possible_matches)
    match_type = (match_type.to_s + '?').to_sym
    if item_info.kind_of?(Array)
      possible_matches.any? do |possibility|
        item_info.any? do |search|
          search.send(match_type, possibility)
        end
      end
    else
      possible_matches.any? do |possibility|
        item_info.send(match_type, possibility)
      end
    end
  end
end

class VariantIdSelector < Selector
  def initialize(match_type, variant_ids)
    @invert = match_type == :not_one
    @variant_ids = variant_ids.map { |id| id.to_i }
  end

  def match?(line_item)
    @invert ^ @variant_ids.include?(line_item.variant.id)
  end
end

class PercentageDiscount
  def initialize(percent, message)
    @discount = (100 - percent) / 100.0
    @message = message
  end

  def apply(line_item)
    line_item.change_line_price(line_item.line_price * @discount, message: @message)
  end
end

class ConditionalDiscount < Campaign
  def initialize(condition, customer_qualifier, cart_qualifier, line_item_selector, discount, max_discounts)
    super(condition, customer_qualifier, cart_qualifier)
    @line_item_selector = line_item_selector
    @discount = discount
    @items_to_discount = max_discounts == 0 ? nil : max_discounts
  end

  def run(cart)
    raise "Campaign requires a discount" unless @discount
    return unless qualifies?(cart)
    applicable_items = cart.line_items.select { |item| @line_item_selector.nil? || @line_item_selector.match?(item) }
    applicable_items = applicable_items.sort_by { |item| item.variant.price }
    applicable_items.each do |item|
      break if @items_to_discount == 0
      if (!@items_to_discount.nil? && item.quantity > @items_to_discount)
        discounted_items = item.split(take: @items_to_discount)
        @discount.apply(discounted_items)
        cart.line_items << discounted_items
        @items_to_discount = 0
      else
        @discount.apply(item)
        @items_to_discount -= item.quantity if !@items_to_discount.nil?
      end
    end
  end
end

class Qualifier
  def partial_match(match_type, item_info, possible_matches)
    match_type = (match_type.to_s + '?').to_sym
    if item_info.kind_of?(Array)
      possible_matches.any? do |possibility|
        item_info.any? do |search|
          search.send(match_type, possibility)
        end
      end
    else
      possible_matches.any? do |possibility|
        item_info.send(match_type, possibility)
      end
    end
  end

  def compare_amounts(compare, comparison_type, compare_to)
    case comparison_type
      when :greater_than
        return compare > compare_to
      when :greater_than_or_equal
        return compare >= compare_to
      when :less_than
        return compare < compare_to
      when :less_than_or_equal
        return compare <= compare_to
      when :equal_to
        return compare == compare_to
      else
        raise "Invalid comparison type"
    end
  end
end

class CartQuantityQualifier < Qualifier
  def initialize(total_method, comparison_type, quantity)
    @total_method = total_method
    @comparison_type = comparison_type
    @quantity = quantity
  end

  def match?(cart, selector = nil)
    case @total_method
      when :item
        total = cart.line_items.reduce(0) do |total, item|
          total + ((selector ? selector.match?(item) : true) ? item.quantity : 0)
        end
      when :cart
        total = cart.line_items.reduce(0) { |total, item| total + item.quantity }
    end
    if @total_method == :line_any || @total_method == :line_all
      method = @total_method == :line_any ? :any? : :all?
      qualified_items = cart.line_items.select { |item| selector ? selector.match?(item) : true }
      qualified_items.send(method) { |item| compare_amounts(item.quantity, @comparison_type, @quantity) }
    else
      compare_amounts(total, @comparison_type, @quantity)
    end
  end
end

CAMPAIGNS = [
  ${promotions
      .filter((promotion) => promotion.type === "BuyXGetX")
      .map((promotion) => {
          return `
    BuyXGetX.new(
        :all,
        nil,
        nil,
        VariantIdSelector.new(
            :is_one,
            [${promotion.buy_products.map((product) =>
                String(product).toString()
            )}]
        ),
        VariantIdSelector.new(
            :is_one,
            [${promotion.buy_products.map((product) =>
                String(product).toString()
            )}]
        ),
          PercentageDiscount.new(
            ${promotion.get_price_discount},
            "${promotion.message}" 
        ),
        ${parseInt(promotion.buy_quantity)},
        ${parseInt(promotion.get_quantity)},
        ${parseInt(promotion.max_uses)}
    ),`;
      })}
  ${promotions
      .filter((promotion) => promotion.type === "BuyXGetX-Diferentes")
      .map((promotion) => {
          return `
    BuyXGetX.new(
        :all,
        nil,
        nil,
        VariantIdSelector.new(
            :is_one,
            [${promotion.buy_products.map((product) =>
                String(product).toString()
            )}]
        ),
        VariantIdSelector.new(
            :is_one,
            [${promotion.get_products.map((product) =>
                String(product).toString()
            )}]
        ),
        PercentageDiscount.new(
            ${promotion.get_price_discount},
            "${promotion.message}" 
        ),
        ${parseInt(promotion.buy_quantity)},
        ${parseInt(promotion.get_quantity)},
        ${parseInt(promotion.max_uses)}
    ),`;
      })}
  ${promotions
      .filter((promotion) => promotion.type === "CONDITIONAL")
      .map((promotion) => {
          return `
    ConditionalDiscount.new(
        :all,
        nil,
        CartQuantityQualifier.new(
            :item,
            :greater_than,
            ${parseInt(promotion.buy_quantity)}
        ),
        VariantIdSelector.new(
            :is_one,
            ["${promotion.buy_products.map((product) =>
                String(product).toString()
            )}"]
        ),
        PercentageDiscount.new(
            ${parseInt(promotion.get_price_discount)},
            "${promotion.message}"
        ),
        ${parseInt(promotion.max_uses)}
    ),`;
      })}
].freeze

# end of promotions, beginning of special discounts

customer = Input.cart.customer

def check_tags(customer)
  disabled = ["disabled"]
  if (customer.tags & disabled).length > 0
    return false
  end
  @etiquetas = [                                #limit: 100.000
    { tag: "colaborador_allnutrition", desc: 30, limit: 128500 },
    { tag: "colaborador_nutraline", desc: 30, limit: 128500 },
    { tag: "colaborador_tervispharma", desc: 30, limit: 128500 },
    { tag: "colaborador_dedalo", desc: 30, limit: 128500 },
    { tag: "colaborador_cunsa", desc: 30, limit: 128500 },
    { tag: "amigos_allnutrition", desc: 25, limit: 100000 },
    { tag: "cercanos_allnutrition", desc: 30, limit: 100000 },
    { tag: "conocidos_allnutrition", desc: 20, limit: 100000 },
    { tag: "familiares_allnutrition", desc: 35, limit: 100000 },
    { tag: "convenio_uchile", desc: 25, limit: 215000 },
  ]
  @customer_valid_tags = @etiquetas.sort_by{ |t| t[:desc] }.reverse.find{ |t| customer.tags.include? t[:tag] }
  @customer_valid_tags ? @customer_valid_tags.values.last(2) : false
end

def get_discount_limit(customer)
  @customer_discount, @limit = check_tags(customer)
  # Valores por defecto, descuento 0, limite 128.500,00
  @descuento = [
    @customer_discount ? @customer_discount : 0,
    @limit ? @limit : 12850000
  ]
end

if customer
 @customer_discount, @cart_limit = get_discount_limit(customer)
end

if customer && @customer_discount > 0 && Input.cart.subtotal_price <= Money.new(cents: @cart_limit * 100)
  puts 'run'
  Input.cart.line_items.each do |line_item|
    product = line_item.variant
    product_price = product.compare_at_price ? product.compare_at_price : product.price
    percent_product_discount = (Decimal.new(100) - Decimal.new(@customer_discount)) / 100.0

    new_line_price = (product_price * percent_product_discount) * line_item.quantity
    if new_line_price < line_item.line_price
      line_item.change_line_price(new_line_price, message: "Descuento Especial All Nutrition")
    end
  end
else
 
CAMPAIGNS.each do |campaign|
  campaign.run_with_hooks(Input.cart)
end
end
Output.cart = Input.cart
`.replace(/,,/g, ",")
        );
    }, [promotions]);

    const copyAllCode = () => {
        setCopied();
        Notification.fire({
            icon: "success",
            title: "Copiado al portapapeles !",
        });
    };

    const renderCopyButton = () => {
        return (
            <Button type="primary" onClick={copyAllCode}>
                {isCopied ? "Copiado!" : "Copiar"}
            </Button>
        );
    };

    return (
        <Card extra={renderCopyButton()}>
            <MonacoEditor
                height="600px"
                language="ruby"
                theme="dark"
                value={code}
                options={{
                    selectOnLineNumbers: false,
                    automaticLayout: true,
                    readOnly: true,
                    formatOnType: true,
                    autoIndent: "full",
                    showFoldingControls: "always",
                    minimap: {
                        enabled: true,
                    },
                }}
                loading={dataLoading}
            />
        </Card>
    );
};

export default PromotionsRubyCode;
