import axios from "axios";
import { SHIPPING_RULES_API_URL } from "../../../../constants";

export const ACTIONS = {
    GET_FREESHIPPING_RULE_LOADING: "GET_FREESHIPPING_RULE_LOADING",
    GET_FREESHIPPING_RULE_SUCCESS: "GET_FREESHIPPING_RULE_SUCCESS",
    GET_FREESHIPPING_RULE_ERROR: "GET_FREESHIPPING_RULE_ERROR",
};

const getFreeShippingData = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.GET_FREESHIPPING_RULE_LOADING,
            });

            const res = await axios.get(
                `${SHIPPING_RULES_API_URL}/rule/free-shipping`
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.GET_FREESHIPPING_RULE_SUCCESS,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.GET_FREESHIPPING_RULE_ERROR,
                payload: error.message,
            });
        }
    };
};

export default getFreeShippingData;
