import React from 'react';
import { Route } from 'react-router-dom';
import PublicTemplate from '../../layouts/PublicTemplate';

const PublicRoute = ({ component, ...otherProps }) => {
    return (
        <PublicTemplate>
            <Route {...otherProps} render={() => React.createElement(component)} />
        </PublicTemplate>
    );
};

export default PublicRoute;
