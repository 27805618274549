import { ACTIONS } from './actions';

const INITIAL_STATE = {
    loading: false,
    success: false,
    error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTIONS.LOGIN_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };
        case ACTIONS.LOGIN_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
