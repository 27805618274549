import React from "react";
import { Modal, Form, Input, InputNumber } from "antd";
import { useDispatch } from "react-redux";
import addVolumeDiscount from "../../store/volume_discounts/add/actions";

const AddVolumeDiscountModal = ({ openModal, setOpenModal }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            dispatch(addVolumeDiscount(values));
            setTimeout(() => {
                handleCloseModal();
            }, 1500);
        });
    };

    return (
        <Modal
            visible={openModal}
            title="Nuevo descuento de volumen"
            okText="Crear"
            cancelText="Cancelar"
            onCancel={handleCloseModal}
            onOk={handleSubmit}
        >
            <Form form={form} layout="vertical" name="volumeDiscountAdd">
                <Form.Item
                    name="message"
                    label="Mensaje del descuento"
                    help="Mensaje que veran los clientes"
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Por favor, completa este campo",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="threshold"
                    label="Monto"
                    help="Monto, ej: 100.000"
                    rules={[
                        {
                            type: "number",
                            required: true,
                        },
                    ]}
                >
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    name="amount"
                    label="Porcentaje"
                    help="Ingresar un porcentaje"
                    rules={[
                        {
                            type: "number",
                            required: true,
                            message: "Por favor, completa este campo",
                            min: 1,
                            max: 100,
                        },
                    ]}
                >
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddVolumeDiscountModal;
