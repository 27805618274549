import React from "react";
import { Row, Col, PageHeader, Card } from "antd";

const NotAuthorized = () => {
    return (
        <Row justify="center">
            <Col span="22" sm="24">
                <PageHeader title="No tienes acceso a esta seccion" />
            </Col>
            <Col span="22" sm="24">
                <Card>
                    <b>Al parecer tu usuario no tiene permisos suficientes para ver esto :(</b>
                </Card>
            </Col>
        </Row>
    );
};

export default NotAuthorized;
