import axios from "axios";
import { VOLUME_DISCOUNTS_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";

export const ACTIONS = {
    GET_ALL_VOLUME_DISCOUNTS_LOADING: "GET_ALL_VOLUME_DISCOUNTS_LOADING",
    GET_ALL_VOLUME_DISCOUNTS_SUCCESS: "GET_ALL_VOLUME_DISCOUNTS_SUCCESS",
    GET_ALL_VOLUME_DISCOUNTS_ERROR: "GET_ALL_VOLUME_DISCOUNTS_ERROR",
};

const getVolumeDiscounts = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.GET_ALL_VOLUME_DISCOUNTS_LOADING,
            });

            const res = await axios.get(`${VOLUME_DISCOUNTS_API_URL}/get/all`, {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                },
            });

            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.GET_ALL_VOLUME_DISCOUNTS_SUCCESS,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.GET_ALL_VOLUME_DISCOUNTS_ERROR,
                payload: error.message,
            });
        }
    };
};

export default getVolumeDiscounts;
