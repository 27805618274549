import axios from "axios";
import { REFUNDS_API_URL } from "../../../constants";

export const ACTIONS = {
    GET_REFUND_LOADING: "GET_REFUND_LOADING",
    GET_REFUND_SUCCESS: "GET_REFUND_SUCCESS",
    GET_REFUND_ERROR: "GET_REFUND_ERROR",
};

const getRefund = (refund_id) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.GET_REFUND_LOADING,
            });

            const res = await axios.get(
                `${REFUNDS_API_URL}/refund/${refund_id}`
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.GET_REFUND_SUCCESS,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.GET_REFUND_ERROR,
                payload: error.message,
            });
        }
    };
};

export default getRefund;
