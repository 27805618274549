import axios from "axios";
import { PROMOTIONS_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";
import history from "../../../helpers/history";
import getPromotions from "../getAll/actions";

export const ACTIONS = {
    UPDATE_PROMOTIONS_LOADING: "UPDATE_PROMOTIONS_LOADING",
    UPDATE_PROMOTIONS_SUCCESS: "UPDATE_PROMOTIONS_SUCCESS",
    UPDATE_PROMOTIONS_ERROR: "UPDATE_PROMOTIONS_ERROR",
};

const updatePromotions = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.UPDATE_PROMOTIONS_LOADING,
            });
            const res = await axios.put(
                `${PROMOTIONS_API_URL}/promotions/update`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${getUserToken()}`,
                    },
                }
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.UPDATE_PROMOTIONS_SUCCESS,
                    payload: res.data,
                });
                history.push("/promotions");
                dispatch(getPromotions());
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.UPDATE_PROMOTIONS_ERROR,
                payload: error.message,
            });
        }
    };
};

export default updatePromotions;
