import React from "react";
import { Table } from "antd";
import moment from "moment";
import TimeConverter from "../../helpers/DateFormat";

const OrderReviewTable = ({ data, loading }) => {
    console.log({ data });
    const columns = [
        { title: "Orden", dataIndex: "order_name", key: "order_name" },
        {
            title: "Calificación",
            dataIndex: "review_stars",
            key: "review_stars",
            render: (stars) => <span>{stars} estrellas</span>,
        },
        { title: "Email", dataIndex: "customer_email", key: "customer_email" },
        {
            title: "Fecha de la orden",
            dataIndex: "order_created_at",
            key: "order_created_at",
            render: (date) =>
                date ? moment.unix(parseInt(date)).format("LLL") : "",
        },
        {
            title: "Creacion de la reseña",
            dataIndex: "created_at",
            key: "created_at",
            render: (date) => moment(parseInt(date)).format("LLL"),
        },
    ];

    return (
        <Table
            loading={loading}
            columns={columns}
            expandable={{
                expandedRowRender: (record) => {
                    return (
                        <div>
                            <p>
                                <b>Razon: </b>{" "}
                                {record.review_reason || "Sin Razon"}
                            </p>
                            <p>
                                <b>Comentario:</b>
                                <br />
                                {record.review_comment}
                            </p>
                        </div>
                    );
                },
            }}
            dataSource={data}
        />
    );
};

export default OrderReviewTable;
