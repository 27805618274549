import axios from "axios";
import { CROSSDOCKING_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";
import getVendors from "../getAll/actions";

export const ACTIONS = {
    ADD_VENDOR_LOADING: "ADD_VENDOR_LOADING",
    ADD_VENDOR_SUCCESS: "ADD_VENDOR_SUCCESS",
    ADD_VENDOR_ERROR: "ADD_VENDOR_ERROR",
};

const addVendor = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ACTIONS.ADD_VENDOR_LOADING });

            const res = await axios.post(
                `${CROSSDOCKING_API_URL}/vendor/add`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${getUserToken()}`,
                    },
                }
            );

            if (res.status === 200) {
                dispatch({ type: ACTIONS.ADD_VENDOR_SUCCESS });
                dispatch(getVendors());
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.ADD_VENDOR_ERROR,
                payload: error.message,
            });
        }
    };
};

export default addVendor;
