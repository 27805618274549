import { Col, PageHeader, Row, Button, Card } from "antd";
import React from "react";
import PromotionsRubyCode from "../components/Promotions/PromotionsRubyCode";
import PromotionsTable from "../components/Promotions/PromotionsTable";
import history from "../helpers/history";
import useAllPromotions from "../hooks/useAllPromotions";

const Promotions = () => {
    const [promotionsLoading, promotionsData, promotionsError] =
        useAllPromotions();

    const renderAddButton = () => {
        return (
            <Button
                type="primary"
                onClick={() => history.push("/promotions/new")}
            >
                Nuevo
            </Button>
        );
    };

    return (
        <React.Fragment>
            <Row justify="center">
                <Col span="22" sm="24">
                    <PageHeader title="Promociones" extra={renderAddButton()} />
                </Col>
            </Row>
            <Row justify="center">
                <Col span="22" sm="24">
                    <PromotionsTable
                        loading={promotionsLoading}
                        data={promotionsData}
                    />
                </Col>
                <Col span="22" sm="24">
                    <PromotionsRubyCode
                        promotions={promotionsData}
                        dataLoading={promotionsLoading}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Promotions;
