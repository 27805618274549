import React, { useEffect, useState } from "react";
import { Select, Form, Input, Button } from "antd";
import createPromotions from "../../store/promotions/create/actions";
import { useDispatch } from "react-redux";
import updatePromotions from "../../store/promotions/update/actions";

const PromotionsForm = ({ formToEdit, initialValues }) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [promotionType, setPromotionType] = useState("BuyXGetX");
    const { Option } = Select;

    useEffect(() => {
        if (Object.keys({ ...initialValues }).length > 0 && initialValues.type)
            setPromotionType(initialValues.type);
    }, [initialValues]);

    const onSubmit = (data) => {
        if (formToEdit)
            return dispatch(
                updatePromotions({ id: initialValues.id, ...data })
            );
        dispatch(createPromotions(data));
    };

    return (
        <Form
            form={form}
            layout="vertical"
            onFinish={onSubmit}
            name="promotions"
            initialValues={
                formToEdit
                    ? initialValues
                    : {
                          type: "BuyXGetX",
                      }
            }
        >
            {/* Tipo de promocion */}
            <Form.Item
                name="type"
                label="Tipo de promocion"
                rules={[
                    {
                        required: true,
                        message: "Selecciona un tipo de promocion",
                    },
                ]}
            >
                <Select onChange={(val) => setPromotionType(val)}>
                    <Option value="BuyXGetX">BuyXGetX</Option>
                    <Option value="BuyXGetX-Diferentes">
                        BuyXGetX Diferentes
                    </Option>
                    <Option value="CONDITIONAL">Conditional Discount</Option>
                </Select>
            </Form.Item>
            {/* Compra esta cantidad */}
            <Form.Item
                name="buy_quantity"
                label="Compra esta cantidad"
                rules={[
                    {
                        required: true,
                        message: "Ingresa una cantidad",
                    },
                ]}
            >
                <Input
                    placeholder="Cantidad requerida para activar descuento"
                    type="number"
                />
            </Form.Item>
            {/* de estos productos */}
            <Form.Item
                name="buy_products"
                label="De estos productos (Variant Id's)"
                rules={[
                    {
                        required: true,
                        message: "Ingresa los productos",
                    },
                ]}
            >
                <Select mode="tags" placeholder="Productos" />
            </Form.Item>

            {(promotionType === "BuyXGetX" ||
                promotionType === "BuyXGetX-Diferentes") && (
                <Form.Item name="get_quantity" label="Y Consigue">
                    <Input placeholder="Numero a obtener" type="number" />
                </Form.Item>
            )}

            {promotionType === "BuyXGetX-Diferentes" && (
                <Form.Item
                    name="get_products"
                    label="De este producto (Variant Id)"
                    help="Variant ID"
                >
                    <Select mode="tags" placeholder="Productos" />
                </Form.Item>
            )}

            {/* Con este descuento */}
            <Form.Item
                name="get_price_discount"
                label="Con este descuento"
                rules={[
                    {
                        required: true,
                        message: "Ingresa una cantidad",
                    },
                ]}
            >
                <Input placeholder="% de descuento a obtener" type="number" />
            </Form.Item>
            <Form.Item
                name="max_uses"
                label="Maximo de usos (por carrito)"
                help="0 para infinito"
            >
                <Input
                    placeholder="Usos de la promocion por carrito"
                    type="number"
                    defaultValue="1"
                    value="1"
                />
            </Form.Item>
            {/* Mensaje */}
            <Form.Item
                name="message"
                label="Mensaje"
                rules={[
                    {
                        required: true,
                        message: "Ingresa una cantidad",
                    },
                ]}
            >
                <Input placeholder="Mensaje de la promocion" />
            </Form.Item>

            <Button type="primary" htmlType="submit">
                {formToEdit ? "Editar" : "Crear"}
            </Button>
            <br />
            <br />
            <span>
                <span>
                    <b>BuyXGetX:</b> Ej 2x1: Si añades 2 proteinas se añadira 1
                    proteina igual gratis o con un % de descuento
                </span>
                <br />
                <br />
                <span>
                    <b>BuyXGetX Diferentes:</b> Ej 2x1: Si añades 2 proteinas se
                    añadira 1 producto que definas o con un % de descuento
                    <br />
                    <b>Otro ej:</b> 1x1, si compras 1 proteina obtienes 1 shaker
                    gratis
                </span>

                <br />
                <br />
                <span>
                    <b>Conditional Discount:</b> Añades 10 proteinas y obtienes
                    un 20% de descuento en el total de estas 10
                </span>
            </span>
        </Form>
    );
};

export default PromotionsForm;
