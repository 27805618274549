import { Button, Input, Tooltip } from "antd";
import React, { useState, useCallback, Fragment } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import updatePriceMatchOptions from "../../store/refunds/updateOptions/actions";

const RefundUpdateOptionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  .numeric-input .ant-tooltip-inner {
    min-width: 32px;
    min-height: 37px;
  }

  .numeric-input .numeric-input-title {
    font-size: 14px;
  }
`;

const PriceMatchUpdate = ({ opt_id }) => {
  //Option id for MailAlerts
  //const opt_id = "opt_dcfa419e-d6ef-48f9-85df-3653d78e130e";
  const [time, settime] = useState("");
  const [quantity, setquantity] = useState("");
  const [email, setemail] = useState("");

  const dispatch = useDispatch();

  const handleUpdateOptions = useCallback(() => {
    dispatch(
      updatePriceMatchOptions({
        opt_id,
        time,
        quantity,
        email,
      })
    );
  }, [time, quantity, email]);

  const handleChangeTime = useCallback(
    (e) => {
      settime(e.target.value);
    },
    [time]
  );
  const handleChangeQuantity = useCallback(
    (e) => {
      setquantity(e.target.value);
    },
    [quantity]
  );
  const handleChangeEmail = useCallback(
    (e) => {
      setemail(e.target.value);
    },
    [email]
  );

  return (
    <RefundUpdateOptionsStyled>
      <Fragment>
        <Tooltip
          trigger={["hover"]}
          title={"Rango de tiempo en minutos de la alerta"}
          placement="right"
          overlayClassName="numeric-input"
        >
          <Input
            style={{ marginBottom: "10px" }}
            onChange={handleChangeTime}
            placeholder="Tiempo"
          />
        </Tooltip>
        <Tooltip
          trigger={["hover"]}
          title={"Cantidad de productos que se compran en el tiempo definido"}
          placement="right"
          overlayClassName="numeric-input"
        >
          <Input
            style={{ marginBottom: "10px" }}
            onChange={handleChangeQuantity}
            placeholder="Cantidad de veces"
          />
        </Tooltip>
        <Tooltip
          trigger={["hover"]}
          title={"¿A quién notificaremos de estas alertas?"}
          placement="right"
          overlayClassName="numeric-input"
        >
          <Input
            style={{ marginBottom: "10px" }}
            onChange={handleChangeEmail}
            placeholder="Email Notificación"
          />
        </Tooltip>
      </Fragment>

      <Button type="primary" onClick={handleUpdateOptions} color="purple">
        Guardar
      </Button>
    </RefundUpdateOptionsStyled>
  );
};
const PriceMatchUpdateAccMail = ({ opt_id }) => {
  const [email, setemail] = useState("");
  const dispatch = useDispatch();

  const handleUpdateOptions = useCallback(() => {
    dispatch(
      updatePriceMatchOptions({
        opt_id,
        email,
        quantity: "na",
        time: "na",
      })
    );
  }, [email]);

  const handleChangeEmail = useCallback(
    (e) => {
      setemail(e.target.value);
    },
    [email]
  );

  return (
    <RefundUpdateOptionsStyled>
      <Fragment>
        <Tooltip
          trigger={["hover"]}
          title={"¿A quién notificaremos de estas alertas?"}
          placement="right"
          overlayClassName="numeric-input"
        >
          <Input
            style={{ marginBottom: "10px" }}
            onChange={handleChangeEmail}
            placeholder="Email Notificación"
          />
        </Tooltip>
      </Fragment>

      <Button type="primary" onClick={handleUpdateOptions} color="purple">
        Guardar
      </Button>
    </RefundUpdateOptionsStyled>
  );
};
const PriceMatchUpdateAdminMail = ({ opt_id }) => {
  const [email, setemail] = useState("");
  const dispatch = useDispatch();

  const handleUpdateOptions = useCallback(() => {
    dispatch(
      updatePriceMatchOptions({
        opt_id,
        email,
        quantity: "na",
        time: "na",
      })
    );
  }, [email]);

  const handleChangeEmail = useCallback(
    (e) => {
      setemail(e.target.value);
    },
    [email]
  );

  return (
    <RefundUpdateOptionsStyled>
      <Fragment>
        <Tooltip
          trigger={["hover"]}
          title={"¿A quién notificaremos de estas alertas?"}
          placement="right"
          overlayClassName="numeric-input"
        >
          <Input
            style={{ marginBottom: "10px" }}
            onChange={handleChangeEmail}
            placeholder="Email Notificación"
          />
        </Tooltip>
      </Fragment>

      <Button type="primary" onClick={handleUpdateOptions} color="purple">
        Guardar
      </Button>
    </RefundUpdateOptionsStyled>
  );
};
export { PriceMatchUpdate, PriceMatchUpdateAccMail, PriceMatchUpdateAdminMail };
