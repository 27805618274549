import { Button, Card } from 'antd';
import React, { useEffect, useState } from 'react';
import {ControlledEditor as MonacoEditor }from '@monaco-editor/react';
import useClipboard from 'react-use-clipboard'
import Notification from '../Utils/Notification'

const RubyDiscountCode = ({discounts, dataLoading}) => {
    const [code, setCode] = useState("")
    const [isCopied, setCopied] = useClipboard(code, {
        successDuration: 2000
    })

    const formatArray = (arr) => {
        return arr.map((discount) => {
            return JSON.stringify({ tag: discount.tag, desc: discount.discount, limit: discount.limit_amount })
        }).join(",\n        ")
    }

    useEffect(() => {
        if(Array.from(discounts)) {setCode(`# Copiar y pegar este codigo en el script editor de shopify, en el script de descuentos especiales
customer = Input.cart.customer
def check_tags(customer)
    disabled = ["disabled"]
    if (customer.tags & disabled).length > 0
        return false
    end
    @etiquetas = [
        ${formatArray(discounts)}
    ]
    @customer_valid_tags = @etiquetas.sort_by{ |t| t[:desc] }.reverse.find{ |t| customer.tags.include? t[:tag] } 
    @customer_valid_tags ? @customer_valid_tags.values.last(2) : false
end
                            
def get_discount_limit(customer)
    @customer_discount, @limit = check_tags(customer)
    # Valores por defecto, descuento 0, limite 128.500,00
    @descuento = [
        @customer_discount ? @customer_discount : 0,
        @limit ? @limit : 12850000
    ]
end
                            
if customer
    @customer_discount, @cart_limit = get_discount_limit(customer)
end
                                
if customer && Input.cart.subtotal_price <= Money.new(cents: @cart_limit)
    Input.cart.line_items.each do |line_item|
        product = line_item.variant
        product_price = product.compare_at_price ? product.compare_at_price : product.price
        percent_product_discount = (Decimal.new(100) - Decimal.new(@customer_discount)) / 100.0
                            
        new_line_price = (product_price * percent_product_discount) * line_item.quantity
        if new_line_price < line_item.line_price
            line_item.change_line_price(new_line_price, message: Input.cart)
        end
    end
end
Output.cart = Input.cart`)
}},[discounts])



    const copyAllCode = () => {
        setCopied()
        Notification.fire({
            icon: 'success',
            title: 'Copiado al portapapeles !'
        })
    }

    const renderCopyButton = () => {
        return (
            <Button type="primary"  onClick={copyAllCode}>{isCopied ? 'Copiado!': 'Copiar'}</Button>
        )
    }

    return ( 
        <Card extra={renderCopyButton()}>
            <MonacoEditor
                height="600px"
                language="ruby"
                theme="dark"
                value={code}
                options={{
                    selectOnLineNumbers: false,
                    automaticLayout:true,
                    readOnly: true,
                    formatOnType: true,
                    autoIndent: "full",
                    showFoldingControls: "always",
                    minimap: {
                        enabled: true
                    }
                }}
                loading={dataLoading}
            /> 
        </Card>
    );
}
 
export default RubyDiscountCode;