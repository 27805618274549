import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ClientDataStyled = styled.div`
    display: flex;
    flex-direction: column;
    p {
        margin-bottom: 5px;
    }
`;

const Paragraph = ({ title, text }) => {
    if (!text) return <></>;
    return (
        <p>
            <b>{title}:</b> {text}
        </p>
    );
};

const RefundClientData = ({ client }) => {
    const { bankData } = client;
    return (
        <ClientDataStyled>
            <Paragraph
                title="Nombre"
                text={bankData.accountName || client.displayName}
            />
            <Paragraph title="Rut" text={bankData.accountRUT} />
            <Paragraph title="Banco" text={bankData.accountBank} />
            <Paragraph title="Tipo de cuenta" text={bankData.accountType} />
            <Paragraph title="Nº Cuenta" text={bankData.accountNumber} />
            <Paragraph
                title="Email"
                text={bankData.accountEmail || client.email}
            />
            {client.phone && <Paragraph title="Telefono" text={client.phone} />}
        </ClientDataStyled>
    );
};

RefundClientData.propTypes = {
    client: PropTypes.shape({
        displayName: PropTypes.string,
        bankData: PropTypes.shape({
            rut: PropTypes.string,
            numero: PropTypes.number,
            email: PropTypes.string,
        }),
    }),
};

export default RefundClientData;
