import React from 'react';
import { Row, Col, PageHeader } from 'antd';

const IndexPage = () => {
    return (
        <Row justify="center">
            <Col span="22" sm="24">
                <PageHeader title="Home" />
            </Col>
            <Col span="22" sm="24"></Col>
        </Row>
    );
};

export default IndexPage;
