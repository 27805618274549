import { Button, Card, Checkbox, InputNumber, Select, Form } from "antd";
import { Option } from "antd/lib/mentions";

import React, { useEffect, useState } from "react";
import comunas from "../../assets/comunas.json";
import { useDispatch } from "react-redux";
import updateFreeShippingRule from "../../store/shippingRules/freeShipping/update/actions";

const FreeShippingRule = ({ initialValues, loading }) => {
    const [cities, setCities] = useState([]);
    const [actived, setActived] = useState(false);

    const dispatch = useDispatch();
    const [form] = Form.useForm();

    useEffect(() => {
        if (!loading) {
            const { config } = initialValues;
            setCities(config?.cities);
            form.setFieldsValue({
                threshold: config?.threshold,
            });
            setActived(initialValues.active);
        }
    }, [initialValues, form, loading]);

    function handleChange(values) {
        setCities([...values]);
    }

    const handleSubmit = (values) => {
        const freeShippingUpdated = {
            rule_type: initialValues.rule_type,
            config: {
                ...values,
                cities,
            },
            active: actived,
        };
        dispatch(updateFreeShippingRule(freeShippingUpdated));
    };
    return (
        <Card title="Despacho Gratuito" loading={loading}>
            <Form
                form={form}
                name="free_shipping"
                layout="vertical"
                onFinish={handleSubmit}
            >
                <Form.Item
                    label="Comunas donde aplica"
                    help="Puedes escribir para encontrar la comuna mas rapido."
                >
                    <Select
                        mode="multiple"
                        style={{ width: "100%" }}
                        placeholder="Seleccionar una o mas comunas."
                        value={cities}
                        onChange={handleChange}
                        optionLabelProp="label"
                    >
                        {Array.from(Object.keys(comunas)).map((comuna) => (
                            <Option value={comuna} label={comuna}>
                                <div className="demo-option-label-item">
                                    {comuna}
                                </div>
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="threshold"
                    label="Monto desde el que aplica"
                    rules={[
                        {
                            type: "number",
                            required: true,
                            message: "Por favor, ingresa un numero valido",
                            min: 1,
                            max: 10000000,
                        },
                    ]}
                >
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item label="Regla activa ?">
                    <Checkbox
                        style={{ lineHeight: "32px" }}
                        checked={actived}
                        onChange={(e) => setActived(e.target.checked)}
                    >
                        Si
                    </Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Actualizar datos
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default FreeShippingRule;
