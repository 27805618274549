export const PICKUPS_LOCATION_API =
    "https://r50uc1idva.execute-api.us-east-1.amazonaws.com/prod";
export const AUTH_API_URL =
    "https://3re48tm8e9.execute-api.us-east-1.amazonaws.com/prod";
export const REFUNDS_API_URL =
    "https://ubnubop4d2.execute-api.us-east-1.amazonaws.com/prod";
export const CROSSDOCKING_API_URL =
    "https://pyx8rkhdc6.execute-api.us-east-1.amazonaws.com/prod";
export const DISCOUNTS_API_URL =
    "https://nch18epf0f.execute-api.us-east-1.amazonaws.com/prod";
export const VOLUME_DISCOUNTS_API_URL =
    "https://s8asce8j2i.execute-api.us-east-1.amazonaws.com/prod";
export const SHIPPING_RULES_API_URL =
    "https://hwcvlduzae.execute-api.us-east-1.amazonaws.com/prod";
export const PROMOTIONS_API_URL =
    "https://xqqv4qopig.execute-api.us-east-1.amazonaws.com/prod";
export const ORDER_REVIEW_API_URL =
    "https://77u9okqdl3.execute-api.us-east-1.amazonaws.com/prod";
