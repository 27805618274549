import React from "react";
import { Modal, Form, Input, InputNumber } from "antd";
import { useDispatch } from "react-redux";
import addDiscount from "../../store/discounts/add/actions";
const AddDiscountModal = ({ openModal, setOpenModal }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSubmit = () => {
        form.validateFields().then((values) => {
            dispatch(addDiscount(values));
            setTimeout(() => {
                handleCloseModal();
            }, 1500);
        });
    };

    return (
        <Modal
            visible={openModal}
            title="Nuevo Descuento"
            okText="Crear"
            cancelText="Cancelar"
            onCancel={handleCloseModal}
            onOk={handleSubmit}
        >
            <Form form={form} layout="vertical" name="discountAdd">
                <Form.Item
                    name="title"
                    label="Titulo del descuento"
                    help="Este nombre se utilizara para diferenciarlo de los otros."
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Por favor, completa este campo",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="discount"
                    label="Porcentaje de descuento"
                    help="Solo ingresar el numero, EJ: 20"
                    rules={[
                        {
                            type: "number",
                            required: true,
                            message:
                                "Por favor, ingresa un numero valido entre 1 y 100",
                            min: 1,
                            max: 100,
                        },
                    ]}
                >
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    name="limit_amount"
                    label="Monto limite mensual"
                    help="Ingresar en CLP, sin decimales, EJ: 80000"
                    rules={[
                        {
                            type: "number",
                            required: true,
                            message: "Por favor, completa este campo",
                            min: 1,
                            max: 10000000,
                        },
                    ]}
                >
                    <InputNumber style={{ width: "100%" }} />
                </Form.Item>
                <Form.Item
                    name="tag"
                    label="Tag asociado"
                    help="Tag asociado al descuento, previamente creado en Shopify"
                    rules={[
                        {
                            type: "string",
                            required: true,
                            message: "Por favor, completa este campo",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default AddDiscountModal;
