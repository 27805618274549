import React from "react";
import { Layout } from "antd";
import styled from "styled-components";
import { logoutUser } from "../../helpers/Auth";
import { LogoutOutlined } from "@ant-design/icons";
import logo from "../../assets/logo.png"
const HeaderGridStyled = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(3, 1fr);
    color: white;
    .logout {
        grid-column: 3;
        align-self: center;
        justify-self: flex-end;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
            opacity: 0.8;
        }
    
    }
    .logo {
        float: left;
        width: 200px;
        margin: 12px;
    }
    .dashboard{
        float:left;
        margin: auto 15px;
    }
    .header__logo{
        float:left;
        margin: auto 0;
    }
`;

const Header = () => {
    return (
        <Layout.Header>
            
            <HeaderGridStyled>
                <div class="header__logo">
                <img
                    className="logo"
                    src={logo}
                />
                <p className="dashboard">| Dashboard</p>
                </div>
                <div className="logout" onClick={() => logoutUser()}>
                    <LogoutOutlined /> Cerrar Sesion
                </div>
            </HeaderGridStyled>
        </Layout.Header>
    );
};

export default Header;
