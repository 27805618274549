import React from 'react';
import { Layout } from 'antd';

const PublicTemplate = ({ children }) => {
    const { Content } = Layout;

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Layout>
                <Content>{children}</Content>
            </Layout>
        </Layout>
    );
};

export default PublicTemplate;
