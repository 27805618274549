import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Table, Input, InputNumber, Popconfirm, Form, Tag } from "antd";
import updateLocation from "../../store/pickupsLocations/update/actions";

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode =
        inputType === "number" ? <InputNumber min={0} max={1} /> : <Input />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Por favor completa ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const PickupsTable = ({ locations, dataLoading }) => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        setData(locations);
    }, [locations]);

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            pickup_name: "",
            show_in_cart: "",
            show_in_product: "",
            latitude: 0,
            longitude: 0,
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const parseBool = (v) => {
        if (v === 0) {
            return false;
        } else if (v > 0) {
            return true;
        }
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey("");

                const updatedObject = {
                    pickup_id: item.pickup_id,
                    pickup_name: row.pickup_name,
                    show_in_cart: parseBool(row.show_in_cart),
                    show_in_product: parseBool(row.show_in_product),
                    coords: {
                        latitude: parseFloat(row.latitude),
                        longitude: parseFloat(row.longitude),
                    },
                };
                dispatch(updateLocation(updatedObject));
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {}
    };

    const columns = [
        {
            title: "Nombre",
            dataIndex: "pickup_name",
            width: "25%",
            key: "pickup_name",
            editable: true,
            render: (value) => {
                return <b style={{ fontWeight: "600" }}>{value}</b>;
            },
        },
        {
            title: "Mostrar en carro",
            dataIndex: "show_in_cart",
            width: "15%",
            key: "show_in_cart",
            editable: true,
            render: (value) => {
                if (value) {
                    return <Tag color="green">Si</Tag>;
                } else {
                    return <Tag color="red">No</Tag>;
                }
            },
        },
        {
            title: "Mostrar en producto",
            dataIndex: "show_in_product",
            width: "15%",
            key: "show_in_product",
            editable: true,
            render: (value) => {
                if (value) {
                    return <Tag color="green">Si</Tag>;
                } else {
                    return <Tag color="red">No</Tag>;
                }
            },
        },
        {
            title: "Latitud",
            dataIndex: "latitude",
            width: "15%",
            key: "latitude",
            editable: true,
        },
        {
            title: "Longitud",
            dataIndex: "longitude",
            width: "15%",
            key: "longitude",
            editable: true,
        },
        {
            title: "Opciones",
            dataIndex: "operation",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <button
                            type="button"
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Guardar
                        </button>
                        <Popconfirm title="Cancelar?" onConfirm={cancel}>
                            <a>Cancelar</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <a
                        disabled={editingKey !== ""}
                        onClick={() => edit(record)}
                    >
                        Editar
                    </a>
                );
            },
        },
    ];

    const setInputType = (dataIndex) => {
        if (dataIndex === "show_in_cart" || dataIndex === "show_in_product") {
            return "number";
        }
        return "text";
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: setInputType(col.dataIndex),
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                loading={dataLoading}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                }}
            />
        </Form>
    );
};

export default PickupsTable;
