import React, { useState, useCallback } from 'react';
import { Input, Form, Button, Card } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import loginUser from '../../store/login/actions';
import FormItem from 'antd/lib/form/FormItem';

const LoginFormHeaderStyled = styled.h1`
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 1.6rem;
    margin-bottom: 2rem;
`;

const ErrorMessageStyled = styled.span`
    display: block;
    color: red;
    font-weight: bold;
    margin: 0 auto;
`;

const SuccessMessageStyled = styled.span`
    display: block;
    color: #513d72;
    font-weight: bold;
    margin: 0 auto;
`;

const LoginForm = () => {
    const dispatch = useDispatch();
    const [formLayout, setFormLayout] = useState('vertical');
    const [form] = Form.useForm();

    const { loading, success, error } = useSelector((store) => store.login);

    const handleSubmit = useCallback((values) => {
        dispatch(loginUser(values));
    }, []);

    return (
        <Card style={{ alignSelf: 'center', marginTop: '4rem' }}>
            <LoginFormHeaderStyled>Ingresar a mi cuenta</LoginFormHeaderStyled>
            <Form layout={formLayout} form={form} name="login" onFinish={handleSubmit}>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[{ required: true, type: 'email', message: 'Por favor ingresa el email.' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Contraseña"
                    rules={[{ required: true, type: 'string', message: 'Por favor ingresa tu contraseña' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Entrar
                    </Button>
                </Form.Item>
                {success && (
                    <Form.Item>
                        <SuccessMessageStyled>Ingresando...</SuccessMessageStyled>
                    </Form.Item>
                )}
                {error && (
                    <Form.Item>
                        <ErrorMessageStyled>Ocurrio un error, comprueba los campos.</ErrorMessageStyled>
                    </Form.Item>
                )}
            </Form>
        </Card>
    );
};

export default LoginForm;
