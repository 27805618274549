import axios from "axios";
import { VOLUME_DISCOUNTS_API_URL } from "../../../constants";
import getVolumeDiscounts from "../getAll/actions";
import { getUserToken } from "../../../helpers/Auth";

export const ACTIONS = {
    DELETE_VOLUME_DISCOUNT_LOADING: "DELETE_VOLUME_DISCOUNT_LOADING",
    DELETE_VOLUME_DISCOUNT_SUCCESS: "DELETE_VOLUME_DISCOUNT_SUCCESS",
    DELETE_VOLUME_DISCOUNT_ERROR: "DELETE_VOLUME_DISCOUNT_ERROR",
};

const deleteVolumeDiscount = (id) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.DELETE_VOLUME_DISCOUNT_LOADING,
            });

            const res = await axios.delete(
                `${VOLUME_DISCOUNTS_API_URL}/delete/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${getUserToken()}`,
                    },
                }
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.DELETE_VOLUME_DISCOUNT_SUCCESS,
                });

                dispatch(getVolumeDiscounts());
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.DELETE_VOLUME_DISCOUNT_ERROR,
                payload: error.message,
            });
        }
    };
};

export default deleteVolumeDiscount;
