import axios from "axios";
import { VOLUME_DISCOUNTS_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";
import getAllVolumeDiscounts from "../getAll/actions";

export const ACTIONS = {
    UPDATE_VOLUME_DISCOUNT_LOADING: "UPDATE_VOLUME_DISCOUNT_LOADING",
    UPDATE_VOLUME_DISCOUNT_SUCCESS: "UPDATE_VOLUME_DISCOUNT_SUCCESS",
    UPDATE_VOLUME_DISCOUNT_ERROR: "UPDATE_VOLUME_DISCOUNT_ERROR",
};

const updateDiscount = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACTIONS.UPDATE_VOLUME_DISCOUNT_LOADING });
        const res = await axios.put(
            `${VOLUME_DISCOUNTS_API_URL}/update`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                },
            }
        );
        if (res.status === 200) {
            dispatch({ type: ACTIONS.UPDATE_VOLUME_DISCOUNT_SUCCESS });
            dispatch(getAllVolumeDiscounts());
        }
    } catch (error) {
        dispatch({
            type: ACTIONS.UPDATE_VOLUME_DISCOUNT_ERROR,
            payload: error.message,
        });
    }
};

export default updateDiscount;
