import axios from "axios";
import { DISCOUNTS_API_URL } from "../../../constants";

export const ACTIONS = {
    GET_ALL_DISCOUNTS_LOADING: "GET_ALL_DISCOUNTS_LOADING",
    GET_ALL_DISCOUNTS_SUCCESS: "GET_ALL_DISCOUNTS_SUCCESS",
    GET_ALL_DISCOUNTS_ERROR: "GET_ALL_DISCOUNTS_ERROR",
};

const getDiscounts = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.GET_ALL_DISCOUNTS_LOADING,
            });

            const res = await axios.get(`${DISCOUNTS_API_URL}/discounts`);
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.GET_ALL_DISCOUNTS_SUCCESS,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.GET_ALL_DISCOUNTS_ERROR,
                payload: error.message,
            });
        }
    };
};

export default getDiscounts;
