import axios from "axios";
import { DISCOUNTS_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";
import getDiscounts from "../getAll/actions";

export const ACTIONS = {
    ADD_DISCOUNT_LOADING: "ADD_DISCOUNT_LOADING",
    ADD_DISCOUNT_SUCCESS: "ADD_DISCOUNT_SUCCESS",
    ADD_DISCOUNT_ERROR: "ADD_DISCOUNT_ERROR",
};

const createDiscount = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ACTIONS.ADD_DISCOUNT_LOADING });

            const res = await axios.post(
                `${DISCOUNTS_API_URL}/discount/create`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${getUserToken()}`,
                    },
                }
            );

            if (res.status === 200) {
                dispatch({ type: ACTIONS.ADD_DISCOUNT_SUCCESS });
                dispatch(getDiscounts());
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.ADD_DISCOUNT_ERROR,
                payload: error.message,
            });
        }
    };
};

export default createDiscount;
