import React, { useState } from "react";
import { Button, Col, PageHeader, Row } from "antd";
import CrossDockingTable from "../components/CrossDocking/CrossDockingTable";
import useCrossDockingVendors from "../hooks/useCrossDockingVendors";
import AddVendorModal from "../components/CrossDocking/AddVendorModal";

const CrossDocking = () => {
    const [loading, data, error] = useCrossDockingVendors();
    const [openModal, setOpenModal] = useState();

    const renderAddButton = () => {
        return (
            <Button type="primary" onClick={() => setOpenModal(true)}>
                Nuevo
            </Button>
        );
    };

    return (
        <Row justify="center">
            <AddVendorModal openModal={openModal} setOpenModal={setOpenModal} />
            <Col span="22" sm="24">
                <PageHeader title="Cross Docking" extra={renderAddButton()} />
            </Col>
            <Col span="22" sm="24">
                <CrossDockingTable vendors={data} dataLoading={loading} />
            </Col>
        </Row>
    );
};

export default CrossDocking;
