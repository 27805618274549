import axios from "axios";
import { DISCOUNTS_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";
import getAllDiscounts from "../getAll/actions";

export const ACTIONS = {
    UPDATE_DISCOUNT_LOADING: "UPDATE_DISCOUNT_LOADING",
    UPDATE_DISCOUNT_SUCCESS: "UPDATE_DISCOUNT_SUCCESS",
    UPDATE_DISCOUNT_ERROR: "UPDATE_DISCOUNT_ERROR",
};

const updateDiscount = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACTIONS.UPDATE_DISCOUNT_LOADING });
        const res = await axios.post(
            `${DISCOUNTS_API_URL}/discount/update`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                },
            }
        );
        if (res.status === 200) {
            dispatch({ type: ACTIONS.UPDATE_DISCOUNT_SUCCESS });
            dispatch(getAllDiscounts());
        }
    } catch (error) {
        dispatch({ type: ACTIONS.UPDATE_DISCOUNT_ERROR, payload: error.message });
    }
};

export default updateDiscount;
