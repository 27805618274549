import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import getFreeShippingData from "../store/shippingRules/freeShipping/get/actions";

const useFreeShippingRule = () => {
    const dispatch = useDispatch();

    const { loading, data, error } = useSelector(
        (store) => store.getFreeShippingRule
    );

    useEffect(() => {
        if (Object.keys(data).length === 0) {
            dispatch(getFreeShippingData());
        }
    }, [dispatch]);

    return [loading, data, error];
};
export default useFreeShippingRule;
