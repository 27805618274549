import jwt from 'jsonwebtoken';
const TOKEN_NAME = 'user_token';

export const saveUserToken = (token) => {
    return localStorage.setItem(TOKEN_NAME, token);
};

export const getUserToken = () => {
    return localStorage.getItem(TOKEN_NAME);
};

export const logoutUser = () => {
    localStorage.removeItem(TOKEN_NAME);
    return (window.location.href = '/login');
};

export const getUserInToken = () => {
    try {
        const decoded = jwt.decode(getUserToken());
        const now = parseInt(Date.now().toString().slice(0, 10));

        if (decoded.exp < now) {
            throw new Error('Token de usuario no valido.');
        }

        return {
            valid: true,
            user: { ...decoded },
        };
    } catch {
        return logoutUser();
    }
};
