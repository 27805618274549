
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import getOptionsAccountantMail from "../store/refunds/getOptionsAccountantMail/actions";

const useOptionAccountantMail = (opt_id) => {
    const dispatch = useDispatch();
    const { loading, data, error } = useSelector((store) => store.getOptionsAccountantMail);
    useEffect(() => {
            dispatch(getOptionsAccountantMail(opt_id));
        
    }, [dispatch]);

    return [loading, data, error];
};
export default useOptionAccountantMail;
