import axios from "axios";
import { PROMOTIONS_API_URL } from "../../../constants";
import getPromotions from "../getAll/actions";

export const ACTIONS = {
    DELETE_PROMOTIONS_LOADING: "DELETE_PROMOTIONS_LOADING",
    DELETE_PROMOTIONS_SUCCESS: "DELETE_PROMOTIONS_SUCCESS",
    DELETE_PROMOTIONS_ERROR: "DELETE_PROMOTIONS_ERROR",
};

const deletePromotions = (id) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.DELETE_PROMOTIONS_LOADING,
            });

            const res = await axios.delete(
                `${PROMOTIONS_API_URL}/promotions/delete/${id}`
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.DELETE_PROMOTIONS_SUCCESS,
                });

                dispatch(getPromotions());
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.DELETE_PROMOTIONS_ERROR,
                payload: error.message,
            });
        }
    };
};

export default deletePromotions;
