import axios from "axios";
import { AUTH_API_URL } from "../../constants";
import { saveUserToken } from "../../helpers/Auth";
import history from "../../helpers/history";

export const ACTIONS = {
    LOGIN_LOADING: "LOGIN_LOADING",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_ERROR: "LOGIN_ERROR",
};

const loginUser = (params) => {
    return async (dispatch) => {
        dispatch({ type: ACTIONS.LOGIN_LOADING });
        try {
            const { email, password } = params;

            if (!email || !password) {
                throw new Error("Debes completar todos los campos.");
            }

            const res = await axios.post(`${AUTH_API_URL}/login`, {
                ...params,
            });

            if (res.status === 200) {
                saveUserToken(res.data.token);
                dispatch({ type: ACTIONS.LOGIN_SUCCESS });
                history.push("/");
            }
        } catch (error) {
            dispatch({ type: ACTIONS.LOGIN_ERROR, payload: error.message });
        }
    };
};

export default loginUser;
