import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import getLocations from "../store/pickupsLocations/getAll/actions";
import shortid from "shortid";

const usePickupLocations = () => {
    const [formatedLocations, setFormatedLocations] = useState([]);
    const dispatch = useDispatch();
    const { loading, data, error } = useSelector(
        (store) => store.pickupsLocations
    );
    useEffect(() => {
        if (!data[0]) {
            dispatch(getLocations());
        }
    }, [dispatch]);

    useEffect(() => {
        if (data.length > 0) {
            setFormatedLocations(
                data.reduce((acc, ele) => {
                    const uniqueKey = shortid.generate();
                    const latitude = ele.coords ? ele.coords.latitude : "";
                    const longitude = ele.coords ? ele.coords.longitude : "";
                    acc.push({
                        key: uniqueKey,
                        ...ele,
                        latitude,
                        longitude,
                        show_in_cart: ele.show_in_cart ? 1 : 0,
                        show_in_product: ele.show_in_product ? 1 : 0,
                    });
                    return acc;
                }, [])
            );
        }
    }, [data]);

    return [loading, formatedLocations, error];
};
export default usePickupLocations;
