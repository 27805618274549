import axios from "axios";
import { CROSSDOCKING_API_URL } from "../../../constants";
import getVendors from "../getAll/actions";

export const ACTIONS = {
    DELETE_VENDOR_LOADING: "DELETE_VENDOR_LOADING",
    DELETE_VENDOR_SUCCESS: "DELETE_VENDOR_SUCCESS",
    DELETE_VENDOR_ERROR: "DELETE_VENDOR_ERROR",
};

const deleteVendor = (vendor_id) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.DELETE_VENDOR_LOADING,
            });

            const res = await axios.delete(
                `${CROSSDOCKING_API_URL}/vendor/delete/${vendor_id}`
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.DELETE_VENDOR_SUCCESS,
                });

                dispatch(getVendors());
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.DELETE_VENDOR_ERROR,
                payload: error.message,
            });
        }
    };
};

export default deleteVendor;
