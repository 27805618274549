import React from "react";
import LoginForm from "../components/Login/Login";
import { Row, Col } from "antd";

const Login = () => {
    return (
        <Row justify="center">
            <Col xs={22} md={12} lg={8} xxl={6}>
                <LoginForm />
            </Col>
        </Row>
    );
};

export default Login;
