import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import PrivateTemplate from "../../layouts/PrivateTemplate";
import { getUserInToken } from "../../helpers/Auth";
import NotAuthorized from "../../pages/NotAuthorized";

const PrivateRoute = ({ component, ...otherProps }) => {
    const [isLogged, setIsLogged] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userInToken, setUserInToken] = useState({});

    useEffect(() => {
        const { valid, user } = getUserInToken();
        if (valid) {
            setIsLogged(true);
            setUserInToken(user);
        }
        setLoading(false);
    }, [loading]);

    if (loading) return "";

    const checkMultipleRoles = (routeRoles) => {
        // Si la ruta no tiene roles o no los requiere lo deja pasar.
        if (!Array.isArray(routeRoles) || !Array.from(routeRoles)[0]) return true;
        // Si en los roles del usuario esta el rol "admin" lo deja pasar.
        if (Array.from(userInToken.roles).includes("admin")) return true;
        // Si alguno de los roles que tiene el usuario es uno de los roles que requiere la ruta lo deja pasar.
        return Array.from(routeRoles).some((r) => Array.from(userInToken.roles).includes(r));
    };

    return isLogged && checkMultipleRoles(otherProps.roles) ? (
        <PrivateTemplate>
            <Route {...otherProps} render={() => React.createElement(component)} />
        </PrivateTemplate>
    ) : (
        <PrivateTemplate>
            <Route {...otherProps} render={NotAuthorized} />
        </PrivateTemplate>
    );
};

export default PrivateRoute;
