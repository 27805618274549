import axios from "axios";
import { CROSSDOCKING_API_URL } from "../../../constants";

export const ACTIONS = {
    GET_ALL_VENDORS_LOADING: "GET_ALL_VENDORS_LOADING",
    GET_ALL_VENDORS_SUCCESS: "GET_ALL_VENDORS_SUCCESS",
    GET_ALL_VENDORS_ERROR: "GET_ALL_VENDORS_ERROR",
};

const getVendors = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.GET_ALL_VENDORS_LOADING,
            });

            const res = await axios.get(`${CROSSDOCKING_API_URL}/vendor/get`);
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.GET_ALL_VENDORS_SUCCESS,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.GET_ALL_VENDORS_ERROR,
                payload: error.message,
            });
        }
    };
};

export default getVendors;
