import axios from "axios";
import { VOLUME_DISCOUNTS_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";
import getVolumeDiscounts from "../getAll/actions";

export const ACTIONS = {
    ADD_VOLUME_DISCOUNT_LOADING: "ADD_VOLUME_DISCOUNT_LOADING",
    ADD_VOLUME_DISCOUNT_SUCCESS: "ADD_VOLUME_DISCOUNT_SUCCESS",
    ADD_VOLUME_DISCOUNT_ERROR: "ADD_VOLUME_DISCOUNT_ERROR",
};

const createVolumeDiscount = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ACTIONS.ADD_VOLUME_DISCOUNT_LOADING });

            const res = await axios.post(
                `${VOLUME_DISCOUNTS_API_URL}/create`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${getUserToken()}`,
                    },
                }
            );

            if (res.status === 200) {
                dispatch({ type: ACTIONS.ADD_VOLUME_DISCOUNT_SUCCESS });
                dispatch(getVolumeDiscounts());
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.ADD_VOLUME_DISCOUNT_ERROR,
                payload: error.message,
            });
        }
    };
};

export default createVolumeDiscount;
