import { ACTIONS } from "./actions";

const INITIAL_STATE = {
    loading: false,
    success: false,
    error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTIONS.UPDATE_VENDOR_LOADING:
            return {
                ...state,
                loading: true,
                success: false,
                error: null,
            };
        case ACTIONS.UPDATE_VENDOR_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                error: null,
            };
        case ACTIONS.UPDATE_VENDOR_ERROR:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
