import axios from "axios";
import { REFUNDS_API_URL } from "../../../constants";

export const ACTIONS = {
    GET_OPTIONS_ACC_MAIL_LOADING: "GET_OPTIONS_ACC_MAIL_LOADING",
    GET_OPTIONS_ACC_MAIL_SUCCESS: "GET_OPTIONS_ACC_MAIL_SUCCESS",
    GET_OPTIONS_ACC_MAIL_ERROR: "GET_OPTIONS_ACC_MAIL_ERROR",
};

const getOptionsAccountantMail = (opt_id) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.GET_OPTIONS_ACC_MAIL_LOADING,
            });

            const res = await axios.get(
                `${REFUNDS_API_URL}/options/${opt_id}`
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.GET_OPTIONS_ACC_MAIL_SUCCESS,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.GET_OPTIONS_ACC_MAIL_ERROR,
                payload: error.message,
            });
        }
    };
};

export default getOptionsAccountantMail;
