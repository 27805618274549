import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import shortid from "shortid";
import getVendors from "../store/crossdocking/getAll/actions";

const useCrossDockingVendors = () => {
    const [formatedData, setFormatedData] = useState([]);
    const dispatch = useDispatch();

    const { loading, data, error } = useSelector(
        (store) => store.crossDockingVendors
    );

    useEffect(() => {
        if (!data[0]) {
            dispatch(getVendors());
        }
    }, [dispatch]);

    useEffect(() => {
        setFormatedData(
            data.reduce((acc, ele) => {
                acc.push({
                    key: shortid.generate(),
                    ...ele,
                });
                return acc;
            }, [])
        );
    }, [data]);

    return [loading, formatedData, error];
};
export default useCrossDockingVendors;
