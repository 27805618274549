import React, { useState, useEffect } from "react";
import { Switch, Router } from "react-router-dom";
import history from "./helpers/history";

// Routes
import PrivateRoute from "./components/Routes/PrivateRoute";
import PublicRoute from "./components/Routes/PublicRoute";

// Pages
import IndexPage from "./pages/index";
import PickupsPage from "./pages/pickups";
import LoginPage from "./pages/login";
import RefundsPage from "./pages/refunds";
import RefundsOptionsPage from "./pages/refundOptions";
import RefundPage from "./pages/refund";
import CrossDockingPage from "./pages/crossdocking";
import DiscountsPage from "./pages/discounts";
import VolumeDiscounts from "./pages/volumeDiscounts";
import ShippingRules from "./pages/ShippingRules";
import PromotionsPage from "./pages/promotions";
import PromotionsNewPage from "./pages/promotionsNew";
import PromotionsEditPage from "./pages/promotionsEdit";
import OrderReviewsPage from "./pages/orderReviewsPage";
// Helpers

function App() {
    return (
        <Router history={history}>
            <Switch>
                <PublicRoute exact path="/login" component={LoginPage} />
                <PrivateRoute exact path="/" component={IndexPage} />

                <PrivateRoute
                    exact
                    path="/pickups"
                    component={PickupsPage}
                    roles={["admin"]}
                />

                <PrivateRoute
                    exact
                    path="/refunds"
                    component={RefundsPage}
                    roles={["contabilidad"]}
                />
                <PrivateRoute
                    exact
                    path="/refunds/options"
                    component={RefundsOptionsPage}
                    roles={["admin"]}
                />

                <PrivateRoute
                    exact
                    path="/refund/:refund_id"
                    component={RefundPage}
                    roles={["contabilidad"]}
                />

                <PrivateRoute
                    exact
                    path="/crossdocking"
                    component={CrossDockingPage}
                    roles={["admin"]}
                />
                <PrivateRoute
                    exact
                    path="/discounts/specials"
                    component={DiscountsPage}
                    roles={["admin"]}
                />
                <PrivateRoute
                    exact
                    path="/discounts/volume"
                    component={VolumeDiscounts}
                    roles={["admin"]}
                />
                <PrivateRoute
                    exact
                    path="/shipping-rules"
                    component={ShippingRules}
                    roles={["admin"]}
                />
                <PrivateRoute
                    exact
                    path="/promotions"
                    component={PromotionsPage}
                    roles={["admin"]}
                />
                <PrivateRoute
                    exact
                    path="/promotions/new"
                    component={PromotionsNewPage}
                    roles={["admin"]}
                />
                <PrivateRoute
                    exact
                    path="/promotions/edit/:id"
                    component={PromotionsEditPage}
                    roles={["admin"]}
                />
                <PrivateRoute
                    exact
                    path="/orders/reviews"
                    component={OrderReviewsPage}
                    roles={["admin"]}
                />
            </Switch>
        </Router>
    );
}

export default App;
