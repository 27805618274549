import React from "react";
import moment from "moment";
import "moment/locale/es";
import history from "../../helpers/history";
import { Table, Tag } from "antd";
import MoneyFormat from "../../helpers/MoneyFormat";
import stateMessages from "../../helpers/PriceMatchStates";

const RefundsTable = ({ loading, data }) => {
    const columns = [
        {
            title: "ID Cliente",
            dataIndex: "client_id",
        },
        {
            title: "Cliente",
            dataIndex: ["client", "displayName"],
            render: (value) => {
                return <b>{value}</b>;
            },
            sorter: {
                compare: (a, b) => a.client.displayName - b.client.displayName,
            },
        },
        {
            title: "Fecha",
            dataIndex: "createdAt",
            render: (value) => {
                return moment(value).locale("es").format("lll");
            },
            sorter: {
                compare: (a, b) => a.createdAt - b.createdAt,
            },
        },

        {
            title: "U. Actualización",
            dataIndex: "updatedAt",
            render: (value, record) => {
                if (value !== record.createdAt) {
                    return moment(value).locale("es").format("lll");
                }
                return <b>Nunca</b>;
            },
            sorter: {
                compare: (a, b) => a.updatedAt - b.updatedAt,
            },
        },
        {
            title: "Estado",
            dataIndex: "request_state",
            render: (value) => {
                const state = stateMessages(value);
                return <Tag color={state.color}>{state.tagText}</Tag>;
            },
            sorter: {
                compare: (a, b) => a.request_state - b.request_state,
            },
        },
        {
            title: "Monto",
            dataIndex: "amount",
            render: (value) => {
                return <b>{MoneyFormat(value)}</b>;
            },
            sorter: {
                compare: (a, b) => a.amount - b.amount,
            },
        },
    ];

    const handleClickRow = (row) => {
        return history.push(`/refund/${row.refund_id}`);
    };

    const handleSelectRow = (record) => {
        return {
            onClick: () => handleClickRow(record),
        };
    };
    return (
        <Table
            columns={columns}
            dataSource={data}
            onRow={handleSelectRow}
            loading={loading}
        />
    );
};

export default RefundsTable;
