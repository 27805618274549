import axios from "axios";
import { PICKUPS_LOCATION_API } from "../../../constants";

export const ACTIONS = {
    GET_LOCATIONS_LOADING: "GET_LOCATIONS_LOADING",
    GET_LOCATIONS_SUCCESS: "GET_LOCATIONS_SUCCESS",
    GET_LOCATIONS_ERROR: "GET_LOCATIONS_ERROR",
};

const getLocationsLoading = () => {
    return {
        type: ACTIONS.GET_LOCATIONS_LOADING,
    };
};

const getLocationsSuccess = (payload) => {
    return {
        type: ACTIONS.GET_LOCATIONS_SUCCESS,
        payload,
    };
};

const getLocationsError = (payload) => {
    return {
        type: ACTIONS.GET_LOCATIONS_ERROR,
        payload,
    };
};

const getLocations = () => async (dispatch) => {
    dispatch(getLocationsLoading());
    try {
        const res = await axios.get(`${PICKUPS_LOCATION_API}/locations/getall`);
        if (res.status === 200) {
            dispatch(getLocationsSuccess(res.data));
        }
    } catch (error) {
        dispatch(getLocationsError(error.message));
    }
};

export default getLocations;
