import axios from "axios";
import { PROMOTIONS_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";

export const ACTIONS = {
    GET_PROMOTIONS_LOADING: "GET_PROMOTIONS_LOADING",
    GET_PROMOTIONS_SUCCESS: "GET_PROMOTIONS_SUCCESS",
    GET_PROMOTIONS_ERROR: "GET_PROMOTIONS_ERROR",
};

const getPromotions = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.GET_PROMOTIONS_LOADING,
            });

            const res = await axios.get(`${PROMOTIONS_API_URL}/promotions`, {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                },
            });
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.GET_PROMOTIONS_SUCCESS,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.GET_PROMOTIONS_ERROR,
                payload: error.message,
            });
        }
    };
};

export default getPromotions;
