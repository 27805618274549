import { Col, PageHeader, Row } from "antd";
import React from "react";
import FreeShippingRule from "../components/ShippingRules/FreeShippingRule";
import useFreeShippingRule from "../hooks/useFreeShippingRule";

const ShippingRules = () => {
    const [
        freeShippingLoading,
        freeShippingData,
        freeShippingError,
    ] = useFreeShippingRule();

    return (
        <React.Fragment>
            <Row justify="center">
                <Col span="22" sm="24">
                    <PageHeader title="Reglas de despacho" />
                </Col>
                <Col span="22" sm="24">
                    <FreeShippingRule
                        initialValues={freeShippingData}
                        loading={freeShippingLoading}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ShippingRules;
