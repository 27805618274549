import axios from "axios";
import { PICKUPS_LOCATION_API } from "../../../constants";
import getAllLocations from "../getAll/actions";
import { getUserToken } from "../../../helpers/Auth";

export const ACTIONS = {
    UPDATE_LOCATIONS_LOADING: "UPDATE_LOCATIONS_LOADING",
    UPDATE_LOCATIONS_SUCCESS: "UPDATE_LOCATIONS_SUCCESS",
    UPDATE_LOCATIONS_ERROR: "UPDATE_LOCATIONS_ERROR",
};

const updateLocationsLoading = () => {
    return {
        type: ACTIONS.UPDATE_LOCATIONS_LOADING,
    };
};

const updateLocationsSuccess = (payload) => {
    return {
        type: ACTIONS.UPDATE_LOCATIONS_SUCCESS,
        payload,
    };
};

const updateLocationsError = (payload) => {
    return {
        type: ACTIONS.UPDATE_LOCATIONS_ERROR,
        payload,
    };
};

const updateLocation = (data) => async (dispatch) => {
    dispatch(updateLocationsLoading());
    try {
        const res = await axios.post(
            `${PICKUPS_LOCATION_API}/locations/update`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                },
            }
        );
        if (res.status === 200) {
            dispatch(updateLocationsSuccess());
            dispatch(getAllLocations());
        }
    } catch (error) {
        dispatch(updateLocationsError(error.message));
    }
};

export default updateLocation;
