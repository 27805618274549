import { Button, Checkbox, Input, Select, Spin } from "antd";
import React, { useState, useCallback, Fragment } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import updateRefundState from "../../../store/refunds/updateState/actions";

import RefundUploadFile from "./RefundUploadFile";

const RefundUpdateStyled = styled.div`
  display: flex;
  flex-direction: column;
  .ant-select {
    margin-bottom: 10px;
  }
`;

const RefundUpdate = ({ state, refund_id }) => {
  const [newState, setNewState] = useState("");
  const [stateComment, setStateComment] = useState("");
  const [sendEmail, setSendEmail] = useState(false);
  const [upload, setUpload] = useState(false);

  const dispatch = useDispatch();

  const handleChangeState = useCallback(
    (v) => {
      if (v !== state) {
        return setNewState(v);
      }
      if (v === state) {
        return setNewState("");
      }
    },
    [newState]
  );
  const handleUpdateState = useCallback(() => {
    let needToSendEmail = false
    if(newState === "accepted"){
      needToSendEmail = true
    }else{
      needToSendEmail = sendEmail
    }
    setUpload(true);
    dispatch(
      updateRefundState({
        refund_id,
        newState,
        stateComment,
        sendEmail: needToSendEmail,
        fileUrl: "No Image",
      })
    );
  }, [newState, stateComment, sendEmail]);

  const handleChangeComment = useCallback(
    (e) => {
      setStateComment(e.target.value);
    },
    [stateComment]
  );

  const handleCheckEmail = useCallback(
    (e) => {
      setSendEmail(e.target.checked);
    },
    [sendEmail]
  );
  
  
  
  return (
    <RefundUpdateStyled>
      <Select defaultValue={state || "pending"} onChange={handleChangeState}>
        <Select.Option value="pending">Pendiente</Select.Option>
        <Select.Option value="revision">En Revision</Select.Option>
        <Select.Option value="accepted">Aceptada</Select.Option>
        <Select.Option value="success">Completada</Select.Option>
        <Select.Option value="deny">Rechazada</Select.Option>
        <Select.Option value="processing" disabled>Procesando Devolución</Select.Option>
      </Select>

      {newState && newState != "accepted" &&  newState != "processing" && (
        <Fragment>
          <Input.TextArea
            style={{ marginBottom: "10px" }}
            onChange={handleChangeComment}
            placeholder="Añade comentarios (opcional)"
          />
          <Checkbox
            style={{ margin: "10px 0px" }}
            checked={sendEmail}
            onChange={handleCheckEmail}
          >
            <strong>Enviar notificacion al cliente</strong>
          </Checkbox>
          <Button
            type="primary"
            disabled={!newState}
            loading={upload}
            onClick={handleUpdateState}
            color="purple"
          >
            {upload ? <Spin />: ""}
            {upload ? "Actualizando": "Actualizar Estado"}
          </Button>
        </Fragment>
      )}
      {newState === "accepted" && (
        <Fragment>
          <Input.TextArea
            style={{ marginBottom: "10px" }}
            onChange={handleChangeComment}
            placeholder="Añade comentarios (opcional)"
          />
          <Checkbox
            style={{ margin: "10px 0px" }}
            checked="true"
            read-only
          >
            <strong>Enviar notificacion al cliente</strong>
          </Checkbox>
          <Button
            type="primary"
            disabled={!newState}
            loading={upload}
            onClick={handleUpdateState}
            color="purple"
          >
            {upload ? <Spin />: ""}
            {upload ? "Actualizando": "Actualizar Estado"}
          </Button>
        </Fragment>
      )}
      {state == "processing" && !newState && (
        <RefundUploadFile refund_id={refund_id} />
      )}
    </RefundUpdateStyled>
  );
};

export default RefundUpdate;
