import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import {
    RetweetOutlined,
    DollarOutlined,
    HomeFilled,
    HomeOutlined,
    DollarCircleFilled,
    UnorderedListOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { getUserInToken } from "../../helpers/Auth";
const { SubMenu } = Menu;

const MenuItems = () => {
    const [loading, setLoading] = useState(true);
    const [userInToken, setUserInToken] = useState({});

    useEffect(() => {
        const { valid, user } = getUserInToken();
        if (valid && user) {
            setUserInToken(user);
        }
        setLoading(false);
    }, [loading]);

    if (loading) return "";

    return (
        <Menu theme="light" defaultActiveFirst mode="inline">
            <Menu.Item key="1" /*icon={<Icon component={HomeIcon} />}*/>
                <Link to="/">
                    <HomeFilled />
                    Home
                </Link>
            </Menu.Item>

            <Menu.Item key="2" /*icon={<Icon component={StoreIcon} />}*/>
                <Link to="/pickups">
                    <HomeOutlined />
                    Tiendas Retiro
                </Link>
            </Menu.Item>

            <SubMenu
                key="sub1"
                title={
                    <span>
                        <DollarCircleFilled />
                        <span>Reembolsos</span>
                    </span>
                }
            >
                <Menu.ItemGroup key="g1">
                    <Menu.Item key="6">
                        <Link to="/refunds">
                            <UnorderedListOutlined />
                            Listado
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="7">
                        <Link to="/refunds/options">
                            <SettingOutlined />
                            Opciones
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </SubMenu>

            <Menu.Item key="4" /*icon={<Icon component={RefundIcon} />}*/>
                <Link to="/crossdocking">
                    <RetweetOutlined />
                    Cross Docking
                </Link>
            </Menu.Item>

            <SubMenu
                key="sub2"
                title={
                    <span>
                        <DollarOutlined />
                        <span>Descuentos</span>
                    </span>
                }
            >
                <Menu.ItemGroup key="sub2-1">
                    <Menu.Item
                        key="sub2-1-1" /*icon={<Icon component={RefundIcon} />}*/
                    >
                        <Link to="/discounts/specials">
                            Descuentos Especiales
                        </Link>
                    </Menu.Item>
                    <Menu.Item
                        key="sub2-1-2" /*icon={<Icon component={RefundIcon} />}*/
                    >
                        <Link to="/discounts/volume">
                            Descuentos por volumen
                        </Link>
                    </Menu.Item>
                </Menu.ItemGroup>
            </SubMenu>

            <Menu.Item key="5">
                <Link to="/shipping-rules">
                    <RetweetOutlined />
                    Reglas de despacho
                </Link>
            </Menu.Item>

            <Menu.Item key="8" /*icon={<Icon component={RefundIcon} />}*/>
                <Link to="/promotions">Promociones</Link>
            </Menu.Item>
            <Menu.Item key="9" /*icon={<Icon component={RefundIcon} />}*/>
                <Link to="/orders/reviews">Reseñas de ordenes</Link>
            </Menu.Item>
        </Menu>
    );
};

export default MenuItems;
