import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import getOptionsMail from "../store/refunds/getOptionsMail/actions";

const useOptionMail = (opt_id) => {
    const dispatch = useDispatch();
    const { loading, data, error } = useSelector((store) => store.getOptionsMail);
    useEffect(() => {
            dispatch(getOptionsMail(opt_id));
        
    }, [dispatch]);

    return [loading, data, error];
};
export default useOptionMail;
