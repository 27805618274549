import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import getRefund from "../store/refunds/getOne/actions";

const useRefund = (refund_id) => {
    const dispatch = useDispatch();
    const { loading, data, error } = useSelector((store) => store.getRefund);
    useEffect(() => {
        if (Object.keys(data).length === 0 || data.refund_id !== refund_id) {
            dispatch(getRefund(refund_id));
        }
    }, [dispatch]);

    return [loading, data, error];
};
export default useRefund;
