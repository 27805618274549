import axios from "axios";
import { PROMOTIONS_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";
import history from "../../../helpers/history";
import getPromotions from "../getAll/actions";

export const ACTIONS = {
    CREATE_PROMOTIONS_LOADING: "CREATE_PROMOTIONS_LOADING",
    CREATE_PROMOTIONS_SUCCESS: "CREATE_PROMOTIONS_SUCCESS",
    CREATE_PROMOTIONS_ERROR: "CREATE_PROMOTIONS_ERROR",
};

const createPromotions = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.CREATE_PROMOTIONS_LOADING,
            });
            console.log(data);
            const res = await axios.post(
                `${PROMOTIONS_API_URL}/promotions/create`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${getUserToken()}`,
                    },
                }
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.CREATE_PROMOTIONS_SUCCESS,
                    payload: res.data,
                });
                history.push("/promotions");
                dispatch(getPromotions());
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.CREATE_PROMOTIONS_ERROR,
                payload: error.message,
            });
        }
    };
};

export default createPromotions;
