import { combineReducers } from "redux";
import login from "./login/reducers";

// Pickup Locations
import pickupsLocations from "./pickupsLocations/getAll/reducers";
import updatePickupLocation from "./pickupsLocations/update/reducers";

// Price Match
import getRefund from "./refunds/getOne/reducers";
import getRefunds from "./refunds/getAll/reducers";
import updateRefundState from "./refunds/updateState/reducers";
import updateOptions from "./refunds/updateOptions/reducers";
import getOptionsMail from "./refunds/getOptionsMail/reducers";
import getOptionsProducts from "./refunds/getOptionsProduct/reducers";
import getOptionsAccountantMail from "./refunds/getOptionsAccountantMail/reducers";
import getOptionsAdminEmail from "./refunds/getOptionsAdminEmail/reducers";

// Cross Docking
import crossDockingVendors from "./crossdocking/getAll/reducers";
import updateCrossDockingVendor from "./crossdocking/update/reducers";
import addCrossDockingVendor from "./crossdocking/add/reducers";
import deleteCrossDockingVendor from "./crossdocking/delete/reducers";

// Discounts
import getDiscounts from "./discounts/getAll/reducers";
import addDiscount from "./discounts/add/reducers";
import deleteDiscount from "./discounts/delete/reducers";
import updateDiscount from "./discounts/update/reducers";

// Volume Discounts
import getVolumeDiscounts from "./volume_discounts/getAll/reducers";
import addVolumeDiscount from "./volume_discounts/add/reducers";
import deleteVolumeDiscount from "./volume_discounts/delete/reducers";
import updateVolumeDiscount from "./volume_discounts/update/reducers";

// ShippingRules
import getFreeShippingRule from "./shippingRules/freeShipping/get/reducers";
import updateFreeShippingRule from "./shippingRules/freeShipping/update/reducers";

// Promotions
import getPromotions from "./promotions/getAll/reducers";
import createPromotions from "./promotions/create/reducers";
import updatePromotions from "./promotions/update/reducers";
import deletePromotions from "./promotions/delete/reducers";

// Order Reviews
import getAllOrderReviews from "./orderReviews/getAll/reducers";

const reducers = combineReducers({
    // Login
    login,
    // Pickups
    pickupsLocations,
    updatePickupLocation,
    // Refunds
    getRefund,
    getRefunds,
    updateRefundState,
    getOptionsMail,
    getOptionsProducts,
    updateOptions,
    getOptionsAccountantMail,
    getOptionsAdminEmail,
    // CrossDocking
    crossDockingVendors,
    updateCrossDockingVendor,
    addCrossDockingVendor,
    deleteCrossDockingVendor,
    // Discounts
    getDiscounts,
    addDiscount,
    deleteDiscount,
    updateDiscount,
    // Volume Discounts,
    getVolumeDiscounts,
    addVolumeDiscount,
    deleteVolumeDiscount,
    updateVolumeDiscount,
    // Free Shipping
    getFreeShippingRule,
    updateFreeShippingRule,
    // Promotions
    getPromotions,
    createPromotions,
    updatePromotions,
    deletePromotions,
    // Order Reviews
    getAllOrderReviews,
});

export default reducers;
