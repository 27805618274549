import { ACTIONS } from "./actions";

const initialState = {
    loading: false,
    data: {},
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTIONS.GET_REFUND_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ACTIONS.GET_REFUND_SUCCESS:
            return {
                ...state,
                loading: false,
                data: { ...action.payload },
                error: null,
            };
        case ACTIONS.GET_REFUND_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;
