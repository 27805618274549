import React from "react";
import styled from "styled-components";
import MoneyFormat from "../../../helpers/MoneyFormat";

const RefundOrderStyled = styled.div`
    display: flex;
    flex-direction: column;
`;

const LineItemStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    ${({ main }) => main && "border: 2px solid #513d72;"}
    padding-right:12px;
    .refund-order__product-info {
        display: flex;
        flex-direction: row;
        img {
            width: 60px;
            height: 80px;
            object-fit: cover;
        }
        span {
            margin-left: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            p {
                margin-bottom: 0px;
            }
        }
    }

    .refund-order__product-price {
        align-self: center;
    }
`;

const RefundOrder = ({ order, product_id }) => {
    const handleRedirectToProduct = (url) => {
        return window.open(url, "_blank");
    };

    return (
        <RefundOrderStyled>
            {Array.from(order.products).map((product) => (
                <LineItemStyled
                    key={product.sku}
                    onClick={() =>
                        handleRedirectToProduct(product.onlineStoreUrl)
                    }
                    main={product.legacyResourceId === product_id}
                >
                    <div className="refund-order__product-info">
                        <img src={product.images} alt="Imagen del producto" />
                        <span>
                            <b>{product.title}</b>
                            <p>
                                <b>{product.variantTitle}</b>
                            </p>
                            <small>
                                <b>{product.vendor}</b>
                            </small>
                            <p>SKU: {product.sku}</p>
                        </span>
                    </div>
                    <div className="refund-order__product-price">
                        <b>
                            {MoneyFormat(
                                product.priceRange.maxVariantPrice.amount
                            )}{" "}
                            x {product.quantity}
                        </b>
                    </div>
                </LineItemStyled>
            ))}
        </RefundOrderStyled>
    );
};

export default RefundOrder;
