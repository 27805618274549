import axios from "axios";
import { REFUNDS_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";

export const ACTIONS = {
    UPDATE_REFUND_STATE_LOADING: "UPDATE_REFUND_STATE_LOADING",
    UPDATE_REFUND_STATE_SUCCESS: "UPDATE_REFUND_STATE_SUCCESS",
    UPDATE_REFUND_STATE_ERROR: "UPDATE_REFUND_STATE_ERROR",
};
const updateRefundState = (data) => async (dispatch) => {
    try {
        dispatch({
            type: ACTIONS.UPDATE_REFUND_STATE_LOADING,
        });
        const res = await axios.post(`${REFUNDS_API_URL}/refund/state/update`, data, {
            headers: {
                "Authorization": `Bearer ${getUserToken()}`,
                "Content-Type": "application/json",

            },
        });
        if (res.status === 200) {
            dispatch({
                type: ACTIONS.UPDATE_REFUND_STATE_SUCCESS,
            });
            setTimeout(() => {
                window.location.reload();
            }, 100);
        }
    } catch (error) {
        console.log({ error, stack: error.stack });
        dispatch({
            type: ACTIONS.UPDATE_REFUND_STATE_ERROR,
            payload: error.message,
        });
    }
};

export default updateRefundState;
