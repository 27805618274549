import axios from "axios";
import { CROSSDOCKING_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";
import getAllVendors from "../getAll/actions";

export const ACTIONS = {
    UPDATE_VENDOR_LOADING: "UPDATE_VENDOR_LOADING",
    UPDATE_VENDOR_SUCCESS: "UPDATE_VENDOR_SUCCESS",
    UPDATE_VENDOR_ERROR: "UPDATE_VENDOR_ERROR",
};

const updateVendor = (data) => async (dispatch) => {
    try {
        dispatch({ type: ACTIONS.UPDATE_VENDOR_LOADING });
        const res = await axios.post(
            `${CROSSDOCKING_API_URL}/vendor/update`,
            data,
            {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                },
            }
        );
        if (res.status === 200) {
            dispatch({ type: ACTIONS.UPDATE_VENDOR_SUCCESS });
            dispatch(getAllVendors());
        }
    } catch (error) {
        dispatch({ type: ACTIONS.UPDATE_VENDOR_ERROR, payload: error.message });
    }
};

export default updateVendor;
