import { Popconfirm, Table } from "antd";
import React from "react";
import { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import deletePromotions from "../../store/promotions/delete/actions";

const PromotionsTable = ({ data, loading }) => {
    const dispatch = useDispatch();
    return (
        <Table
            loading={loading}
            columns={[
                {
                    title: "Mensaje",
                    dataIndex: "message",
                    key: "message",
                },
                {
                    title: "Tipo",
                    dataIndex: "type",
                    key: "type",
                },
                {
                    title: "A comprar",
                    dataIndex: "buy_quantity",
                    key: "buy_quantity",
                },
                {
                    title: "Producto a comprar",
                    dataIndex: "buy_products",
                    key: "buy_products",
                    render: (products) => {
                        if (products.length === 1) return products[0];
                        return `${Array.from(products || []).length} productos`;
                    },
                },
                {
                    title: "A Obtener",
                    dataIndex: "get_quantity",
                    key: "get_quantity",
                    render: (quantity) =>
                        quantity && quantity > 0
                            ? quantity
                            : "Aplica sobre el mismo",
                },
                {
                    title: "ID Producto a obtener",
                    dataIndex: "get_products",
                    key: "get_products",
                    render: (products, row) => {
                        if (row.buy_products[0] === row.get_products[0])
                            return "El mismo";
                        return `${
                            Array.from(products)[0]
                                ? Array.from(products)[0]
                                : "No aplica"
                        }`;
                    },
                },
                {
                    title: "% Descuento",
                    dataIndex: "get_price_discount",
                    key: "get_price_discount",
                },
                {
                    title: "Max de usos",
                    dataIndex: "max_uses",
                    key: "max_uses",
                },
                {
                    title: "Operaciones",
                    dataIndex: "operation",
                    render: (_, record) => {
                        return (
                            <Fragment>
                                <Link to={`/promotions/edit/${record.id}`}>
                                    <strong>Editar</strong>
                                </Link>
                                <Popconfirm
                                    title="Estas seguro de eliminar esta promocion ?"
                                    okText="Eliminar"
                                    cancelText="Cancelar"
                                    okButtonProps={{ danger: true }}
                                    onConfirm={() =>
                                        dispatch(deletePromotions(record.id))
                                    }
                                >
                                    <a
                                        style={{
                                            marginLeft: "1rem",
                                            color: "red",
                                        }}
                                    >
                                        <strong>Eliminar</strong>
                                    </a>
                                </Popconfirm>
                            </Fragment>
                        );
                    },
                },
            ]}
            dataSource={data}
        />
    );
};

export default PromotionsTable;
