import { Button, Col, PageHeader, Row } from "antd";
import React, { useState } from "react";
import VolumeDiscountsTable from "../components/VolumeDiscounts/VolumeDiscountsTable";
import RubyVolumeDiscountCode from "../components/VolumeDiscounts/RubyVolumeDiscountCode";
import useAllVolumeDiscounts from "../hooks/useAllVolumeDiscounts";
import AddVolumeDiscountModal from "../components/VolumeDiscounts/AddVolumeDiscountModal";

const VolumeDiscounts = () => {
    const [openModal, setOpenModal] = useState();
    const [loading, data, error] = useAllVolumeDiscounts();

    const renderAddButton = () => {
        return (
            <Button type="primary" onClick={() => setOpenModal(true)}>
                Nuevo
            </Button>
        );
    };

    return (
        <React.Fragment>
            <Row justify="center">
                <AddVolumeDiscountModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
                {/* VOLUME DISCOUNTS TABLE */}
                <Col span="22" sm="24">
                    <PageHeader
                        title="Descuentos por volumen"
                        extra={renderAddButton()}
                    />
                </Col>
                <Col span="22" sm="24">
                    <VolumeDiscountsTable
                        discounts={data}
                        dataLoading={loading}
                    />
                </Col>
            </Row>
            <Row justify="center">
                {/* CODIGO */}
                <Col span="22" sm="24">
                    <PageHeader title="Codigo" />
                </Col>
                <Col span="22" sm="24">
                    <RubyVolumeDiscountCode
                        volume_discounts={Array.from(data)}
                        dataLoading={loading}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default VolumeDiscounts;
