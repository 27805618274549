import { Button, Card } from "antd";
import React, { useEffect, useState } from "react";
import { ControlledEditor as MonacoEditor } from "@monaco-editor/react";
import useClipboard from "react-use-clipboard";
import Notification from "../Utils/Notification";

const RubyVolumeDiscountCode = ({ volume_discounts, dataLoading }) => {
    const [code, setCode] = useState("");
    const [isCopied, setCopied] = useClipboard(code, {
        successDuration: 2000,
    });

    const formatArray = (arr) => {
        const formatedArray = arr.map((volumeDiscount) => {
            return {
                threshold: parseInt(volumeDiscount.threshold),
                discount_type: ":percent",
                discount_amount: parseInt(volumeDiscount.amount),
                discount_message: volumeDiscount.message,
            };
        });
        return JSON.stringify(formatedArray, null, 2)
            .replace(/[\[\]']+/g, "")
            .replace(/":percent"/g, ":percent")
            .replace(/"threshold"/g, "threshold")
            .replace(/"discount_type"/g, "discount_type")
            .replace(/"discount_amount"/g, "discount_amount")
            .replace(/"discount_message"/g, "discount_message");
    };

    useEffect(() => {
        if (Array.from(volume_discounts)) {
            setCode(`

SPENDING_THRESHOLDS = [${formatArray(volume_discounts)}]

class DiscountApplicator
  def initialize(discount_type, discount_amount, discount_message)
    @discount_type = discount_type
    @discount_message = discount_message

    @discount_amount = if discount_type == :percent
      1 - (discount_amount * 0.01)
    else
      Money.new(cents: 100) * discount_amount
    end
  end

  def apply(line_item)
    new_line_price = if @discount_type == :percent
      line_item.line_price * @discount_amount
    else
      [line_item.line_price - (@discount_amount * line_item.quantity), Money.zero].max
    end

    line_item.change_line_price(new_line_price, message: @discount_message)
  end
end

class TieredDiscountBySpendCampaign
  def initialize(tiers)
    @tiers = tiers.sort_by { |tier| tier[:threshold] }.reverse
  end

  def run(cart)
    applicable_tier = @tiers.find { |tier| cart.subtotal_price >= (Money.new(cents: 100) * tier[:threshold]) }
    return if applicable_tier.nil?

    discount_applicator = DiscountApplicator.new(
      applicable_tier[:discount_type],
      applicable_tier[:discount_amount],
      applicable_tier[:discount_message]
    )

    cart.line_items.each do |line_item|
      next if line_item.variant.product.gift_card?
      discount_applicator.apply(line_item)
    end
  end
end

CAMPAIGNS = [
  TieredDiscountBySpendCampaign.new(SPENDING_THRESHOLDS),
]

CAMPAIGNS.each do |campaign|
  campaign.run(Input.cart)
end

Output.cart = Input.cart
              
              
            `);
        }
    }, [volume_discounts]);

    const copyAllCode = () => {
        setCopied();
        Notification.fire({
            icon: "success",
            title: "Copiado al portapapeles !",
        });
    };

    const renderCopyButton = () => {
        return (
            <Button type="primary" onClick={copyAllCode}>
                {isCopied ? "Copiado!" : "Copiar"}
            </Button>
        );
    };

    return (
        <Card extra={renderCopyButton()}>
            <MonacoEditor
                height="600px"
                language="ruby"
                theme="dark"
                value={code}
                options={{
                    selectOnLineNumbers: false,
                    automaticLayout: true,
                    readOnly: true,
                    formatOnType: true,
                    autoIndent: "full",
                    showFoldingControls: "always",
                    minimap: {
                        enabled: true,
                    },
                }}
                loading={dataLoading}
            />
        </Card>
    );
};

export default RubyVolumeDiscountCode;
