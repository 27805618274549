import { Button, Card, Col, PageHeader, Row } from "antd";
import React, { useState } from "react";
import AddDiscountModal from "../components/Discounts/AddDiscountModal";
import DiscountsTable from "../components/Discounts/DiscountsTable";
import RubyDiscountCode from "../components/Discounts/RubyDiscountCode";
import useAllDiscounts from "../hooks/useAllDiscounts";

const Discounts = () => {
    const [openModal, setOpenModal] = useState();
    const [loading, data, error] = useAllDiscounts();

    const renderAddButton = () => {
        return (
            <Button type="primary" onClick={() => setOpenModal(true)}>
                Nuevo
            </Button>
        );
    };

    return (
        <React.Fragment>
            <Row justify="center">
                <AddDiscountModal
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                />
                {/* DISCOUNTS TABLE */}
                <Col span="22" sm="24">
                    <PageHeader
                        title="Descuentos Especiales"
                        extra={renderAddButton()}
                    />
                </Col>
                <Col span="22" sm="24">
                    <DiscountsTable discounts={data} dataLoading={loading} />
                </Col>
            </Row>
            <Row justify="center">
                {/* CODIGO */}
                <Col span="22" sm="24">
                    <PageHeader title="Codigo" />
                </Col>
                <Col span="22" sm="24">
                    <RubyDiscountCode
                        discounts={Array.from(data)}
                        dataLoading={loading}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Discounts;
