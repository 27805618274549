import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import shortid from "shortid";
import getPromotions from "../store/promotions/getAll/actions";

const useAllPromotions = () => {
    const [formatedData, setFormatedData] = useState([]);

    const dispatch = useDispatch();
    const { loading, data, error } = useSelector(
        (store) => store.getPromotions
    );

    useEffect(() => {
        if (!data[0]) {
            dispatch(getPromotions());
        }
    }, [dispatch]);

    useEffect(() => {
        setFormatedData(
            data.reduce((acc, ele) => {
                acc.push({
                    key: shortid.generate(),
                    ...ele,
                });
                return acc;
            }, [])
        );
    }, [data]);

    return [loading, formatedData, error];
};
export default useAllPromotions;
