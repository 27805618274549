import axios from "axios";
import { DISCOUNTS_API_URL } from "../../../constants";
import getDiscounts from "../getAll/actions";

export const ACTIONS = {
    DELETE_DISCOUNT_LOADING: "DELETE_DISCOUNT_LOADING",
    DELETE_DISCOUNT_SUCCESS: "DELETE_DISCOUNT_SUCCESS",
    DELETE_DISCOUNT_ERROR: "DELETE_DISCOUNT_ERROR",
};

const deleteDiscount = (discount_id) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.DELETE_DISCOUNT_LOADING,
            });

            const res = await axios.delete(
                `${DISCOUNTS_API_URL}/discount/delete/${discount_id}`
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.DELETE_DISCOUNT_SUCCESS,
                });

                dispatch(getDiscounts());
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.DELETE_DISCOUNT_ERROR,
                payload: error.message,
            });
        }
    };
};

export default deleteDiscount;
