import { ACTIONS } from './actions';

const INITIAL_STATE = {
    loading: false,
    data: [],
    error: null,
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ACTIONS.GET_LOCATIONS_LOADING:
            return {
                ...state,
                loading: true,
                data: [],
                error: null,
            };
        case ACTIONS.GET_LOCATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null,
            };
        case ACTIONS.GET_LOCATIONS_ERROR:
            return {
                ...state,
                loading: false,
                data: [],
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
