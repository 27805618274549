import { Button, Col, PageHeader, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import OrderReviewTable from "../components/OrderReviews/OrderReviewTable";
import TimeConverter from "../helpers/DateFormat";
import useAllOrderReviews from "../hooks/useAllOrderReviews";

const OrderReviewsPage = () => {
    const [loading, data, error] = useAllOrderReviews();
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        if (data[0]) {
            const csvColumns = [
                "order_name",
                "review_stars",
                "customer_email",
                "order_created_at",
                "created_at",
            ];

            setCsvData(
                data.reduce(
                    (acc, ele) => {
                        acc.push([
                            ele.order_name,
                            ele.review_stars,
                            ele.customer_email,
                            moment
                                .unix(parseInt(ele.order_created_at))
                                .format("LL"),
                            moment(ele.created_at).format("LL"),
                        ]);
                        return acc;
                    },
                    [csvColumns]
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    return (
        <React.Fragment>
            <Row justify="center">
                <Col span="22" sm="24">
                    <PageHeader
                        title="Reviews de ordenes"
                        extra={[
                            <CSVLink
                                data={csvData}
                                filename="orders_reviews.csv"
                            >
                                <Button>
                                    <b>Descargar CSV</b>
                                </Button>
                            </CSVLink>,
                        ]}
                    />
                </Col>
                <Col span="22" sm="24">
                    <OrderReviewTable data={data} loading={loading} />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OrderReviewsPage;
