import React from "react";
import { Layout } from "antd";
import MenuItems from "./MenuItems";

const Sider = (props) => {
    const { Sider } = Layout;
    return (
        <Sider theme="light" {...props} collapsedWidth={0}>
            <MenuItems />
        </Sider>
    );
};

export default Sider;
