import axios from "axios";
import { SHIPPING_RULES_API_URL } from "../../../../constants";
import { getUserToken } from "../../../../helpers/Auth";
import getFreeShippingData from "../get/actions";

export const ACTIONS = {
    UPDATE_FREESHIPPING_RULE_LOADING: "UPDATE_FREESHIPPING_RULE_LOADING",
    UPDATE_FREESHIPPING_RULE_SUCCESS: "UPDATE_FREESHIPPING_RULE_SUCCESS",
    UPDATE_FREESHIPPING_RULE_ERROR: "UPDATE_FREESHIPPING_RULE_ERROR",
};

const updateFreeShippingData = (data) => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.UPDATE_FREESHIPPING_RULE_LOADING,
            });

            const res = await axios.put(
                `${SHIPPING_RULES_API_URL}/rule/update`,
                data,
                {
                    headers: {
                        Authorization: `Bearer ${getUserToken()}`,
                    },
                }
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.UPDATE_FREESHIPPING_RULE_SUCCESS,
                });
                dispatch(getFreeShippingData());
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.UPDATE_FREESHIPPING_RULE_ERROR,
                payload: error.message,
            });
        }
    };
};

export default updateFreeShippingData;
