import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Table, Input, InputNumber, Popconfirm, Form } from "antd";
import updateVolumeDiscount from "../../store/volume_discounts/update/actions";
import deleteVolumeDiscount from "../../store/volume_discounts/delete/actions";
import MoneyFormat from "../../helpers/MoneyFormat";

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
}) => {
    const inputNode = inputType === "number" ? <InputNumber /> : <Input />;

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0,
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Por favor completa ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const VolumeDiscountsTable = ({ discounts, dataLoading }) => {
    const [form] = Form.useForm();
    const [data, setData] = useState([]);
    const [editingKey, setEditingKey] = useState("");

    const dispatch = useDispatch();

    useEffect(() => {
        setData([...discounts]);
    }, [discounts]);

    const isEditing = (record) => record.key === editingKey;

    const edit = (record) => {
        form.setFieldsValue({
            message: "",
            threshold: 0,
            amount: 0,
            createdAt: 0,
            updatedAt: 0,
            ...record,
        });
        setEditingKey(record.key);
    };

    const cancel = () => {
        setEditingKey("");
    };

    const save = async (key) => {
        try {
            const row = await form.validateFields();
            const newData = [...data];
            const index = newData.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
                setData(newData);
                setEditingKey("");
                const updatedObject = {
                    ...row,
                    vdis_id: item.vdis_id,
                };
                dispatch(updateVolumeDiscount(updatedObject));
            } else {
                newData.push(row);
                setData(newData);
                setEditingKey("");
            }
        } catch (errInfo) {}
    };

    const handleDeleteVolumeDiscount = (id) => {
        dispatch(deleteVolumeDiscount(id));
    };

    const columns = [
        {
            title: "Mensaje",
            dataIndex: "message",
            key: "message",
            editable: true,
            render: (value) => {
                return <b style={{ fontWeight: "600" }}>{value}</b>;
            },
        },
        {
            title: "Monto",
            dataIndex: "threshold",
            key: "threshold",
            editable: true,
            render: (value) => {
                return MoneyFormat(value);
            },
        },
        {
            title: "Porcentaje",
            dataIndex: "amount",
            key: "amount",
            editable: true,
            render: (value) => {
                return `${value}%`;
            },
        },
        {
            title: "Opciones",
            dataIndex: "operation",
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
                        <button
                            type="button"
                            onClick={() => save(record.key)}
                            style={{
                                marginRight: 8,
                            }}
                        >
                            Guardar
                        </button>
                        <Popconfirm title="Cancelar?" onConfirm={cancel}>
                            <a>Cancelar</a>
                        </Popconfirm>
                    </span>
                ) : (
                    <Fragment>
                        <a
                            disabled={editingKey !== ""}
                            onClick={() => edit(record)}
                        >
                            <strong>Editar</strong>
                        </a>
                        <Popconfirm
                            disabled={editingKey !== ""}
                            title="Estas seguro de eliminar este descuento ?"
                            okText="Eliminar"
                            cancelText="Cancelar"
                            okButtonProps={{ danger: true }}
                            onConfirm={() =>
                                handleDeleteVolumeDiscount(record.vdis_id)
                            }
                        >
                            <a style={{ marginLeft: "1rem", color: "red" }}>
                                <strong>Eliminar</strong>
                            </a>
                        </Popconfirm>
                    </Fragment>
                );
            },
        },
    ];

    const setInputType = (dataIndex) => {
        if (dataIndex === "threshold" || dataIndex === "amount") {
            return "number";
        }
        return "text";
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: (record) => ({
                record,
                inputType: setInputType(col.dataIndex),
                dataIndex: col.dataIndex,
                title: col.title,
                editing: isEditing(record),
            }),
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell,
                    },
                }}
                loading={dataLoading}
                bordered
                dataSource={data}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                    onChange: cancel,
                }}
            />
        </Form>
    );
};

export default VolumeDiscountsTable;
