import React, { Fragment } from "react";
import { Timeline } from "antd";
import moment from "moment";
import "moment/locale/es";
import styled from "styled-components";
import stateMessages from "../../../helpers/PriceMatchStates";
import { DownloadOutlined } from "@ant-design/icons";

const StateCommentStyled = styled.div`
    line-height: 12px;
`;
const OverflowContainer =  styled.div`
    height:300px;
    overflow-y:auto;
    scroll:smooth;
`;

const RefundTimeLine = ({ updates = [] }) => {
    const parseDate = (date) => {
        return moment(date).locale("es").format("lll");
    };
    return (
        <Timeline>
            <OverflowContainer>
            {Array.from(updates).map((update) => {
                const state = stateMessages(update.to);
                return (
                    <Timeline.Item color={state.color} key={update.log_id}>
                        {parseDate(update.date)}  <b>{state.timelineText}</b>
                        {update.changedBy && (
                            <Fragment>
                                <br />
                                <small>
                                    <strong>Por:</strong> {update.changedBy || ""}
                                </small>
                            </Fragment>
                        )}
                        {update.stateComment && (
                            <StateCommentStyled>
                                <br />
                                <small style={{ lineHeight: "12px" }}>{update.stateComment}</small>
                            </StateCommentStyled>
                        )}
                        {state.timelineText === "Solicitud completada" && update.fileUrl != "No Image" && (
                            <StateCommentStyled>
                                <br />
                                <small style={{ lineHeight: "12px" }}>
                                    <a href={update.fileUrl} target="_blank">
                                        <DownloadOutlined /> Comprobante
                                    </a>
                                </small>
                            </StateCommentStyled>
                        )}
                    </Timeline.Item>
                );
            })}
            </OverflowContainer>
        </Timeline>
    );
};

export default RefundTimeLine;
