import axios from "axios";
import { ORDER_REVIEW_API_URL } from "../../../constants";
import { getUserToken } from "../../../helpers/Auth";

export const ACTIONS = {
    GET_ORDER_REVIEWS_LOADING: "GET_ORDER_REVIEWS_LOADING",
    GET_ORDER_REVIEWS_SUCCESS: "GET_ORDER_REVIEWS_SUCCESS",
    GET_ORDER_REVIEWS_ERROR: "GET_ORDER_REVIEWS_ERROR",
};

const getAllOrderReviews = () => {
    return async (dispatch) => {
        try {
            dispatch({
                type: ACTIONS.GET_ORDER_REVIEWS_LOADING,
            });

            const res = await axios.get(
                `${ORDER_REVIEW_API_URL}/order/reviews`,
                {
                    headers: {
                        Authorization: `Bearer ${getUserToken()}`,
                    },
                }
            );
            if (res.status === 200) {
                dispatch({
                    type: ACTIONS.GET_ORDER_REVIEWS_SUCCESS,
                    payload: res.data,
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.GET_ORDER_REVIEWS_ERROR,
                payload: error.message,
            });
        }
    };
};

export default getAllOrderReviews;
