import React, { useState, useCallback } from 'react';
import Header from '../components/Header';
import Sider from '../components/Sider';
import { Layout } from 'antd';

const PrivateTemplate = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const { Content } = Layout;

    const handleCollapse = useCallback(() => {
        setCollapsed(!collapsed);
    }, [collapsed]);

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Header />
            <Layout>
                <Sider breakpoint="lg" collapsible collapsed={collapsed} onCollapse={handleCollapse} />
                <Content>{children}</Content>
            </Layout>
        </Layout>
    );
};

export default PrivateTemplate;
