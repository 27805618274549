import React, { useState, useCallback } from "react";
import { Button, Checkbox, Input, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import updateRefundState from "../../../store/refunds/updateState/actions";

function RefundTest(props) {
  const { refund_id } = props;
  const [refundId, setRefundId] = useState(refund_id);
  const [files, setFiles] = useState([]);
  const [upload, setUpload] = useState(false);
  const [stateComment, setStateComment] = useState("");
  const [sendEmail, setSendEmail] = useState(false);

  const dispatch = useDispatch();

  const handleChangeComment = useCallback(
    (e) => {
      setStateComment(e.target.value);
    },
    [stateComment]
  );

  const handleCheckEmail = useCallback((e) => {
    setSendEmail(e.target.checked);
  }, []);

  const uploadFiles = async (files, refund_id, stateComment, sendEmail) =>
    new Promise(async (resolve, reject) => {
      Array.from(files).map(async (file) => {
        const fileName = file.name;
        const fileType = file.type;

        const res = await fetch(
          "https://4l5l2i4pw4.execute-api.us-east-1.amazonaws.com/prod/upload",
          {
            method: "POST",
            body: JSON.stringify({
              fileName,
              fileType,
              folder: "refunds",
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const resJson = await res.json();
        if (res.status === 200) {
          resolve({
            signedRequest: resJson.signedRequest,
            url: resJson.url,
            file,
            fileType,
            refund_id,
            stateComment,
            sendEmail,
          });
        } else {
          reject("No se generó urls");
        }
      });
    });
  const uploadFile = async ({
    signedRequest,
    fileType,
    file,
    url,
    refund_id,
    stateComment,
    sendEmail,
  }) =>
    new Promise(async (resolve, reject) => {
      const res = await fetch(signedRequest, {
        method: "PUT",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": fileType,
        },
        body: file,
      });
      if (res.status === 200) {
        
        setFiles([]);
        resolve({ refund_id, stateComment, sendEmail, url });
      } else {
        reject("PUT didnt work out");
      }
    });

  function updateStateWithFile(data) {
    const { refund_id, stateComment, sendEmail, url } = data;
    dispatch(
      updateRefundState({
        refund_id,
        newState: "success",
        stateComment,
        sendEmail,
        fileUrl: url,
      })
    );
  }

  const handleUpload = useCallback(() => {
    setUpload(true);
    uploadFiles(files, refundId, stateComment, sendEmail)
      .then((result) => {
        console.log(result);
        uploadFile(result).then((result) => {
          console.log(result);
          updateStateWithFile(result)
        });
      })
      .catch((error) => console.log(error));
  }, [files, refundId, stateComment, sendEmail]);

  const uploadProps = {
    onRemove: () => {
      setFiles([]);
    },
    beforeUpload: (file) => {
      setFiles([file]);
      console.log(files);
      return false;
    },
  };
  return (
    <>
      <Input.TextArea
        style={{ marginBottom: "10px" }}
        onChange={handleChangeComment}
        placeholder="Añade comentarios (opcional)"
      />
      <Upload {...uploadProps}>
        <Button icon={<UploadOutlined />}>Elegir Archivo</Button>
      </Upload>

      <Checkbox
        style={{ margin: "10px 0px" }}
        checked={sendEmail}
        onChange={handleCheckEmail}
      >
        <strong>Enviar notificacion al cliente</strong>
      </Checkbox>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={files === ""}
        loading={upload}
        style={{ marginTop: 16 }}
      >
        {upload ? "Subiendo" : "Enviar comprobante"}
      </Button>
    </>
  );
}

export default RefundTest;
