import React from "react";
import styled from "styled-components";
import TimeConverter from "../../helpers/DateFormat";
import useOptionMail from "../../hooks/useOptionsMail"
import useOptionProduct from "../../hooks/useOptionsProduct"
import useOptionAccountantMail from "../../hooks/useOptionAccountantMail"
import useOptionsAdminEmail from "../../hooks/useOptionsAdminEmail"

const RefundOrderStyled = styled.div`
    display: flex;
    flex-direction: column;
`;

const LineItemStyled = styled.div`
    display: flex;
    flex-direction: row;
`;


const PriceMatchOptionsMail = ({ opt_id }) => {
    const [loading, data, error] = useOptionMail(opt_id);

    const {
        time = "",
        email = "",
        quantity = "",
        updatedAt = "",

    } = data;
    return (
        <RefundOrderStyled>
                <LineItemStyled>
                   <ul>
                        <li>Si un producto recibe {quantity} solicitudes de pricematch
                            en {time} minutos, entonces envía una alerta.</li>
                        <li>Email: {email}</li>
                        <li>U. Actualización: {TimeConverter(updatedAt)}</li>
                    </ul>
                </LineItemStyled>
            
        </RefundOrderStyled>
    );
};
const PriceMatchOptionsProduct = ({ opt_id }) => {
    const [loading, data, error] = useOptionProduct(opt_id);

    const {
        time = "",
        email = "",
        quantity = "",
        updatedAt = "",

    } = data;
    return (
        <RefundOrderStyled>
                <LineItemStyled>
                    <ul>
                        <li>Si un producto recibe {quantity} solicitudes de pricematch
                            en {time} minutos, entonces desactiva el producto y envía una alerta.</li>
                        <li>Email: {email}</li>
                        <li>U. Actualización: {TimeConverter(updatedAt)}</li>
                    </ul>
                </LineItemStyled>
            
        </RefundOrderStyled>
    );
};
const PriceMatchOptionsAccountantMail = ({ opt_id  }) => {
    const [loading, data, error] = useOptionAccountantMail(opt_id);

    const {
        email = "",
        updatedAt = "",

    } = data;
    return (
        <RefundOrderStyled>
                <LineItemStyled>
                    <ul>
                        <li>Email: {email}</li>
                        <li>U. Actualización: {TimeConverter(updatedAt)}</li>
                    </ul>
                </LineItemStyled>
            
        </RefundOrderStyled>
    );
};
const PriceMatchOptionsAdminMail = ({ opt_id  }) => {
    const [loading, data, error] = useOptionsAdminEmail(opt_id);

    const {
        email = "",
        updatedAt = "",

    } = data;
    return (
        <RefundOrderStyled>
                <LineItemStyled>
                    <ul>
                        <li>Email: {email}</li>
                        <li>U. Actualización: {TimeConverter(updatedAt)}</li>
                    </ul>
                </LineItemStyled>
            
        </RefundOrderStyled>
    );
};
export { PriceMatchOptionsMail, PriceMatchOptionsProduct, PriceMatchOptionsAccountantMail, PriceMatchOptionsAdminMail };
